import { Link } from "react-router-dom";
import sc from "../assets/img/oneschool/Website/Images/sc.png";
import "./HeroSection2Styles.css";

const HeroSection5 = () => {
  return (
    <div>
      <div className="white-bg-container">
        <div className="container">
          <section className="hero-section">
            <div className="text-container d-flex flex-column justify-content-center align-items-start">
              <h1 className="heading lh-sm">Summer Camps</h1>
              <p className="para">
                We partner with providers to deliver you camps across the UK.
                Join us to discover life in the UK and immerse yourself in the
                most traditional educational settings in the UK
              </p>
              <Link to="/enquire" className="inquire-button">
                Enroll Now
              </Link>
            </div>
            <div className="image-container-a">
              <img src={sc} alt="People interacting" />
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default HeroSection5;
