import { useState, useEffect } from "react";
import oneSchoolIcon from "../assets/img/oneschool/Website/Icons/OneSchoolFullIcon.svg";
import { TimelineSectionAbout } from "./TimelineSectionAbout";

export const OurJourneyAbout = () => {
  const [activeYear, setActiveYear] = useState("2016");
  const [animate, setAnimate] = useState(false);

  const sections = {
    2016: "Khurram moved to China after university, and began offering English Language support to families.",
    2018: "Shahryar joined Khurram after a 3-year stint in Investment Banking. Together, they established One School to give international families the support they wished they had.",
    2019: "Due to the pandemic, the team returned to the UK. One School focused on improving and optimising the quality of teaching and services. This was the beginning of student enrolment in the UK and introduction of small class sizes for students in the UK and in China.",
    2020: "During the pandemic, the One School team moved back to the UK, where we expanded our services to a full syllabus.",
    2022: "One School formalised our educational consultancy services and expanded our subject offering to cover A-Levels and IB.",
    2023: "One School became a BSA-certified agent, and established a new support services HQ in London to provide dedicated support to students in the UK.",
    2024: "One School became approved as a full online school by Cambridge International Education. We were also shortlisted in the BSA Supporting Excellence Awards for supporting International Boarders.",
    9999: "We are a worldwide organisation, with offices in London, Beijing, and Sharjah. Our students come from China, Kazakhstan, Pakistan, USA, and Mexico.",
  };

  const handleSectionClick = () => {
    const years = Object.keys(sections);
    const currentIndex = years.indexOf(activeYear);
    const nextIndex = (currentIndex + 1) % years.length;
    setAnimate(true);
    setTimeout(() => {
      setActiveYear(years[nextIndex]);
      setAnimate(false);
    }, 500);
  };

  // Get the index of the active year and determine if the layout should be reversed
  const years = Object.keys(sections);
  const currentIndex = years.indexOf(activeYear);
  const isReversed = currentIndex % 2 !== 0;

  return (
    <div className="white-bg-container">
      <div className="our-journey-container">
        <div className="our-journey-header">
          <h1>Our Journey</h1>
          <img
            src={oneSchoolIcon}
            alt="One School"
            className="our-journey-icon"
          />
        </div>
        <TimelineSectionAbout
          year={activeYear}
          content={sections[activeYear]}
          onSectionClick={handleSectionClick}
          isReversed={isReversed}
          isActive={animate}
          isNext={!animate && activeYear !== "2016"}
        />
      </div>
    </div>
  );
};

export default OurJourneyAbout;
