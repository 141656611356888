export const TimelineCard = ({ year, description, isSelected, onSelect }) => {
  return (
    <div
      className={`timeline-card ${isSelected ? "expanded" : ""} ${
        year.label === "Senior school" ? "r" : "rr"
      } ${year.cardColor}`}
      onClick={onSelect}
    >
      {!isSelected && (
        <div
          className={`card-info ${year.label === "Senior school" ? "ss" : ""} ${
            year.label === "Year 6" ||
            year.label === "Year 8" ||
            year.label === "Senior school"
              ? ""
              : "hi"
          }`}
        >
          {year.subtitle && <p className="card-subtitle">{year.subtitle}</p>}
          {year.icons && (
            <img
              src={year.icons[0]}
              alt={`${year.subtitle} icon`}
              className="card-icon"
            />
          )}
        </div>
      )}
      <h3>{year.label}</h3>
      {isSelected && (
        <div
          className={`d-flex justify-content-center flex-wrap ${
            year.label === "Senior school" ? "some-gap" : ""
          }`}
        >
          {year.icons.map((icon, i) => {
            if (
              year.label !== "Year 6" &&
              year.label !== "Year 8" &&
              year.label !== "Senior school"
            ) {
              return (
                <img
                  src={icon}
                  className={
                    year.label === "Senior school"
                      ? "card-icon-2"
                      : "card-icon-1"
                  }
                />
              );
            }
            if (
              (year.label === "Year 6" && i <= year.icons.length - 2) ||
              (year.label === "Year 8" && i <= year.icons.length - 2) ||
              (year.label === "Senior school" && i <= year.icons.length - 2)
            ) {
              return (
                <img
                  src={year.icons[i + 1]}
                  className={`${
                    year.label === "Senior school"
                      ? "card-icon-3"
                      : "card-icon-1"
                  } rounded-0`}
                />
              );
            }
          })}
        </div>
      )}
      {isSelected && (
        <div className="d-flex flex-column card-desc">
          {year.description.map((desc) => {
            return <p>{desc}</p>;
          })}
        </div>
      )}
    </div>
  );
};
