import React, { useState, useEffect } from "react";
import "./HomePage.css";
import "./Blog.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-circular-progressbar/dist/styles.css";
import axios from "axios";
import { baseUrl } from "./constants";
import { ContactSection } from "./ContactSection";
import { HeroSectionBlog } from "./HeroSectionBlog";
import { BlogSectionBlog } from "./BlogSectionBlog";

const Blog = () => {
  const [blogs, setBlogs] = useState([]);
  const [headerBlog, setHeaderBlog] = useState({});
  const [tags, setTags] = useState([]);
  const [filteredBlogs, setFilteredBlogs] = useState([]);

  useEffect(function () {
    // fetch blogs
    axios
      .get(`${baseUrl}/blogs/list/`)
      .then((res) => {
        console.log(res);

        const headerBlog = res.data[0];
        setHeaderBlog(headerBlog);

        const nonHeaderBlogs = [];
        res.data.forEach((blog, i) => {
          if (i !== 0) {
            nonHeaderBlogs.push(blog);
          }
        });
        setBlogs(nonHeaderBlogs);
        setFilteredBlogs(nonHeaderBlogs);
      })
      .catch((err) => console.log(err));

    // fetch tags
    axios
      .get(`${baseUrl}/tags/`)
      .then((res) => {
        console.log(res);
        setTags(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleTagsFilter = (e) => {
    const tag = e.target.innerText.toLowerCase();
    const filteredBlogs = [];
    blogs.forEach((blog) => {
      if (blog.tags.includes(tag)) {
        filteredBlogs.push(blog);
      }
    });
    setFilteredBlogs(filteredBlogs);
  };

  return (
    <div>
      <HeroSectionBlog
        headerBlog={headerBlog}
        handleTagsFilter={handleTagsFilter}
      />
      <BlogSectionBlog
        blogs={blogs}
        tags={tags}
        filteredBlogs={filteredBlogs}
        setFilteredBlogs={setFilteredBlogs}
        handleTagsFilter={handleTagsFilter}
      />
    </div>
  );
};

export default Blog;
