import { Link } from "react-router-dom";

export const HeroSectionBlog = ({ headerBlog, handleTagsFilter }) => {
  return (
    <div className="white-bg-container">
      {Object.keys(headerBlog).length > 0 && (
        <section className="hero-section-blog">
          <div className="text-container-blog">
            <Link to={`/blog/${headerBlog.blog_id}`}>
              <h1>{headerBlog.title}</h1>
            </Link>
            <p>{headerBlog.preview_text}</p>
            <div className="tag-container">
              {headerBlog.tags.map((tag, i) => {
                return (
                  <button key={i} className="tag" onClick={handleTagsFilter}>
                    <span>{tag}</span>
                  </button>
                );
              })}
            </div>
            <Link to={`/blog/${headerBlog.blog_id}`} className="inquire-button">
              Read More
            </Link>
          </div>
          <div className="image-container-blog">
            <img src={headerBlog.image_url} alt="People interacting" />
          </div>
        </section>
      )}
    </div>
  );
};
