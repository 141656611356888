import "./DedicationSectionAboutUsStyles.css";

const DedicationSectionAboutUs = () => {
  return (
    <div className="bg-light-blue text-dark text-start text-sm-center">
      <div className="container py-5 ">
        <div className="dedication-section mx-auto">
          <h6>ABOUT US</h6>
          <h1>Dedication to excellence in education</h1>
          <p>
            One School is a British education services provider that supports
            both families and schools to change pupils' lives for the better
            through Excellence in Education.
          </p>
          <p>
            We are recognised by the UK government as both experts and leaders
            in education, Cambridge University as a fully accredited Cambridge
            Online International School, the UK Boarding Schools Association as
            one of the top 3 educational consultants in the world, the Council
            of British International Schools (COBIS) as a leading provider of
            education services to British International Schools globally, the
            Tutor's Association for our outstanding teachers, and other
            world-renowned education bodies.
          </p>
          <p>
            One School is unrivalled as an education services provider in China,
            in the UK & internationally. The outstanding results our pupils
            achieve are unmatched by similar institutions.
          </p>
          <h5>
            Excellence in Education is not just our story. It is our history,
            our value, and our strategy. It it is who we are, and it is what we
            do.
          </h5>
        </div>
      </div>
    </div>
  );
};

export default DedicationSectionAboutUs;
