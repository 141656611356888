import "./HomePage.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-circular-progressbar/dist/styles.css";
import { ContactSection } from "./ContactSection";
import { Testimonials } from "./Testimonials";
import { AboutUsSection } from "./AboutUsSection";
import { ServicesSection } from "./ServicesSection";
import { PhilosophySection } from "./PhilosophySection";
import { MeetOurTeachers } from "./MeetOurTeachers";
import { HeroSectionHome } from "./HeroSectionHome";
import { ResultsSection } from "./ResultsSection";
import ExcellenceInEducationSection from "./ExcellenceInEducationSection";
import LinksSection from "./LinksSection";

const HomePage = () => {
  return (
    <div>
      <HeroSectionHome />
      <ExcellenceInEducationSection />
      {/* <Testimonials /> */}
      {/* <AboutUsSection /> */}
      <ServicesSection />
      <ResultsSection bg='blue' />
      {/* <PhilosophySection /> */}
      <LinksSection />
      {/* <MeetOurTeachers /> */}
    </div>
  );
};

export default HomePage;
