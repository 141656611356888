import { Link } from "react-router-dom";
import academicSupport from "../assets/img/oneschool/Website/Images/AcademicSupportImage.jpg";

export const HeroSectionTutoring = () => {
  return (
    <div className="white-bg-container">
      <section className="hero-section-tutoring">
        <div className="text-container-tutoring">
          <h1>One school academic support</h1>
          <p>
            Our educational philosophy is centred around a systematic approach,
            emphasising both short-term and long-term planning, and providing
            guidance and supervision from experienced teachers at different
            stages.
          </p>
          <Link to="/enquire" className="inquire-button btn-width">
            INQUIRE NOW
          </Link>
        </div>
        <div className="image-container-tutor">
          <img src={academicSupport} alt="People interacting" />
        </div>
      </section>
    </div>
  );
};
