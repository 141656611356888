import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./BlogPage.css";
import axios from "axios";
import { baseUrl } from "./constants";
import parse from "html-react-parser";

const BlogPage = () => {
  const { pathname } = useLocation();
  const blogId = pathname.split("/")[2];

  const [blog, setBlog] = useState({});

  useEffect(function () {
    axios
      .get(`${baseUrl}/blogs/${blogId}/`)
      .then((res) => {
        console.log(res);
        setBlog(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <div className="white-bg-container">
        <div className="container py-5 text-black">
          {Object.keys(blog).length > 0 ? (
            <>
              <h1>{blog.title}</h1>
              {blog.blogContent.map((blogContent) => {
                if (blogContent.type === "image") {
                  return (
                    <div key={blogContent.id} className="row my-5">
                      <div className="col-12 col-md-8 mx-auto">
                        <img
                          src={blogContent.url}
                          className="img-fluid shadow br-20"
                        />
                      </div>
                    </div>
                  );
                }
                if (blogContent.type === "video") {
                  return (
                    <div key={blogContent.id} className="row my-5">
                      <div className="col-12 col-md-8 mx-auto">
                        <div className="video-container mx-auto">
                          <video controls className="br-20">
                            <source src={blogContent.url} />
                          </video>
                        </div>
                      </div>
                    </div>
                  );
                }
                if (blogContent.type === "content") {
                  return (
                    <div key={blogContent.id} className="row my-5">
                      {parse(blogContent.content)}
                    </div>
                  );
                }
              })}
            </>
          ) : null}
        </div>
      </div>

    </>
  );
};

export default BlogPage;
