import artDramaImage from "../assets/img/oneschool/Website/Images/ArtandDrama.jpg";
import biologyImage from "../assets/img/oneschool/Website/Images/Biology.jpg";
import chemistyImage from "../assets/img/oneschool/Website/Images/Chemistry.jpg";
import economicsImage from "../assets/img/oneschool/Website/Images/Economics.png";
import englishLiteratureImage from "../assets/img/oneschool/Website/Images/EnglishLiterature.jpg";
import historyImage from "../assets/img/oneschool/Website/Images/History.jpg";
import mathsImage from "../assets/img/oneschool/Website/Images/Maths.jpg";
import physicsImage from "../assets/img/oneschool/Website/Images/Physics.jpg";
import eslImage from "../assets/img/oneschool/Website/Images/IELTS.png";
import { SubjectCard } from "./SubjectCard";

const subjectsData = [
    { name: "Art & Drama", image: artDramaImage },
    { name: "Biology", image: biologyImage },
    { name: "Chemistry", image: chemistyImage },
    { name: "Economics", image: economicsImage },
    { name: "English literature", image: englishLiteratureImage },
    { name: "History", image: historyImage },
    { name: "Maths", image: mathsImage },
    { name: "Physics", image: physicsImage },
    { name: "ESL / UKISET / IELTS", image: eslImage },
  ];

export const SubjectsSection = () => (
    <div className="blue-bg-container">
      <div className="subjects-section">
        <h2 className="subjects-title">Subjects</h2>
        <div className="subjects-grid">
          {subjectsData.map((subject, index) => (
            <SubjectCard key={index} {...subject} />
          ))}
        </div>
      </div>
    </div>
  );