import { useRef } from "react";
import Slider from "react-slick";
import danielImage from "../assets/img/oneschool/Website/Images/Daniel.jpg";
import aoifeImage from "../assets/img/oneschool/Website/Images/Aoife.jpg";
import ollieImage from "../assets/img/oneschool/Website/Images/Ollie.jpg";
import yellowArrow from "../assets/img/oneschool/Website/Icons/yellow_arrow.svg";
import { IconContext } from "react-icons/lib";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

// Custom arrow components for the slider
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "black" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "black" }}
      onClick={onClick}
    />
  );
}

const teacherProfiles = [
  {
    name: "Daniel",
    image: danielImage,
    description: [
      "Honours degree in Modern Languages and History from the University of Leeds.",
      "Over 7 years of specialised guidance experience for the UK entrance exams, including 11+, 13+, and 16+.",
      "Successfully assisted over 100 Chinese students in applying to top private schools in the UK.",
    ],
  },
  {
    name: "Aoife",
    image: aoifeImage,
    description: [
      "Master's degree in Education and English as a Second Language(ESL) teaching.",
      "Fully qualified with a PGCE certification and TESOL certificate.",
      "Over 12 years of experience teaching English and Mathematics at primary schools in international settings, including the UK, Ireland, and other European countries.",
    ],
  },
  {
    name: "Ollie",
    image: ollieImage,
    description: [
      "Dual Master's degrees in Mathematics and Physics, & fully qualified PGCE certification.",
      "Over 12 years of extensive teaching and academic research experience in international and private schools.",
      "Specializing in IB Mathematics and Physics, providing tutoring to hundreds of IBDP students.",
    ],
  },
  {
    name: "Test 1",
    image: aoifeImage,
    description: [
      "Honours degree in Modern Languages and History from the University of Leeds.",
      "Over 7 years of specialised guidance experience for the UK entrance exams, including 11+, 13+, and 16+.",
      // add more description points here...
    ],
  },
  {
    name: "Test 2",
    image: ollieImage,
    description: [
      "Honours degree in Modern Languages and History from the University of Leeds.",
      "Over 7 years of specialised guidance experience for the UK entrance exams, including 11+, 13+, and 16+.",
      // add more description points here...
    ],
  },
];

export const MeetOurTeachers = () => {
  const sliderRef = useRef();

  const settings = {
    dots: true, // Hide dots
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1310, // Your breakpoint for medium screens
        settings: {
          slidesToShow: 2, // Only show two slides
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, // Your breakpoint for smaller screens
        settings: {
          slidesToShow: 1, // Only show one slide
          slidesToScroll: 1,
          swipeToSlide: true, // Allow touch sliding
          touchMove: true,
        },
      },
    ],
  };

  return (
    <div className="blue-bg-container">
      <div className="container">
        <div className="meet-our-teachers-section">
          <h2 className="section-main">TEAM</h2>
          <h3 className="section-title">Meet our teachers</h3>
          <p className="section-description">
            We recruit only outstanding teachers. This process includes
            interviews, teaching demonstrations, and background checks. Many of
            our teachers are curriculum designers and examiners for IGCSEs /
            A-levels / IB.
          </p>
          <Slider ref={sliderRef} {...settings}>
            {teacherProfiles.map((teacher, index) => (
              <div className="teacher-card" key={index}>
                <img
                  src={teacher.image}
                  alt={teacher.name}
                  className="teacher-image"
                />
                <h4 className="teacher-name">{teacher.name}</h4>
                <ul className="teacher-description">
                  {teacher.description.map((point, index) => (
                    <li key={index}>
                      <img
                        src={yellowArrow}
                        alt="bullet point"
                        className="arrow-icon"
                      />{" "}
                      {point}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </Slider>
          <div className="carousel-controls-teacher-container">
            <button
              className="carousel-control-teachers prev"
              onClick={() => sliderRef.current.slickPrev()}
            >
              <IconContext.Provider value={{ className: "button-icon-left" }}>
                <FaChevronLeft />
              </IconContext.Provider>
              Previous
            </button>
            <button
              className="carousel-control-teachers next"
              onClick={() => sliderRef.current.slickNext()}
            >
              Next
              <IconContext.Provider value={{ className: "button-icon-right" }}>
                <FaChevronRight />
              </IconContext.Provider>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
