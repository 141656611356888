import { Link } from "react-router-dom";
import st1 from "../assets/img/oneschool/Website/Images/st1.png";
import './HeroSection2Styles.css'

const HeroSection2 = () => {
  return (
    <div>
      <div className="white-bg-container">
        <div className="container">
          <section className="hero-section">
            <div className="text-container d-flex flex-column justify-content-center align-items-start">
              <h1 className="heading">Subject Tutoring</h1>
              <p className="para">
              Academic success, like any success, comes through daily habits. Our goal is to empower students to take ownership of their learning, to help them learn how to learn, and motivate them to focus on simple, consistent actions that deliver big results.
              </p>
              <Link to="/enquire" className="inquire-button">
                Enroll Now
              </Link>
            </div>
            <div className="image-container-a">
              <img src={st1} alt="People interacting" />
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default HeroSection2;
