import "./OurCurriculumStyles.css";

const OurCurriculum = () => {
  return (
    <>
      <div className="blue-bg-container text-black">
        <div className=" our-curriculum container py-5 px-5">
          <h1 className="fw-bold mb-5">Our Curriculum</h1>
          <div className="row mb-0 mb-lg-5">
            <div className="col-12 col-lg-5 mb-5 mb-lg-0">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h4>Key Stage 2</h4>
                <h5>Year 3 - Year 6</h5>
              </div>
              <p>
                Key Stage 2 classes at One School follow the UK national
                curriculum. Our classes are split between Lower Key Stage 2 and
                Upper Key Stage 2, but all lessons are tailored to students'
                individual learning plans
              </p>
              <h6>
                We offer classes in Mathematics, English, Science, and Reasoning
              </h6>
            </div>
            <div className="col-0 col-lg-2"></div>
            <div className="col-12 col-lg-5 mb-5 mb-lg-0">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h4>Key Stage 3</h4>
                <h5>Year 7 - Year 9</h5>
              </div>
              <p>
                Our Key Stage 3 courses use our philosophy to prepare students
                for the academic rigours of the British education system. By the
                end of KS3, our students begin to form an idea of their
                strengths, weaknesses, and interests as they move into secondary
                education
              </p>
              <h6>
                We offer classes in Maths, English, Biology, Chemistry, and
                Physics{" "}
              </h6>
            </div>
          </div>
          <div className="row mb-0 mb-lg-5">
            <div className="col-12 col-lg-5 mb-5 mb-lg-0">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h4>Key Stage 4</h4>
                <h5>Year 10 - Year 11</h5>
              </div>
              <p>
                Our Cambridge International Education IGCSE qualifications offer
                both comprehensive and challenging syllabi, equipping students
                with the skills and knowledge to excel academically and setting
                them up for future success
              </p>
              <h6>See below for what subjects we offer at KS4</h6>
            </div>
            <div className="col-0 col-lg-2"></div>
            <div className="col-12 col-lg-5 mb-5 mb-lg-0">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h4>Key Stage 5</h4>
                <h5>Year 12 - Year 13</h5>
              </div>
              <p>
                Whether students take A-levels or follow the International
                Baccalaureate Diploma Programme, One School will be there to
                support students as they explore their passions and prepare them
                for university and beyond
              </p>
              <h6>See below for what subjects we offer at KS5</h6>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OurCurriculum;
