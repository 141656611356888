import { Link } from "react-router-dom";

export const BlogCardBlog = ({
  blog_id,
  title,
  preview_text,
  image_url,
  tags,
  i,
  handleTagsFilter,
}) => (
  <>
    {Math.floor(i % 2) === 0 ? (
      <div className="blog-card my-5">
        <div className="row">
          <div className="col-md-6">
            <img
              src={image_url}
              alt="Blog Title"
              className="blog-image shadow"
            />
          </div>
          <div className="col-md-6">
            <div>
              <Link to={`/blog/${blog_id}`}>
                <h3 className="blog-title">{title}</h3>
              </Link>
              <p className="blog-excerpt">{preview_text}</p>
              <div className="tag-container v-margin">
                {tags.map((tag, i) => (
                  <button key={i} className="tag" onClick={handleTagsFilter}>
                    <span>{tag}</span>
                  </button>
                ))}
              </div>
              <div className="d-flex">
                <Link to={`/blog/${blog_id}`} className="read-more-btn">
                  Read more
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div className="blog-card my-5">
        <div className="row reverse-on-small">
          <div className="col-md-6">
            <div>
              <Link to={`/blog/${blog_id}`}>
                <h3 className="blog-title">{title}</h3>
              </Link>
              <p className="blog-excerpt">{preview_text}</p>
              <div className="tag-container v-margin">
                {tags.map((tag, i) => (
                  <div key={i} className="tag">
                    <span>{tag}</span>
                  </div>
                ))}
              </div>
              <div className="d-flex">
                <Link to={`/blog/${blog_id}`} className="read-more-btn">
                  Read more
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <img
              src={image_url}
              alt="Blog Title"
              className="blog-image shadow"
            />
          </div>
        </div>
      </div>
    )}
  </>
);
