import forStudents7 from "../assets/img/oneschool/Website/Images/forStudents7.jpg";
import forStudents8 from "../assets/img/oneschool/Website/Images/forStudents8.jpg";
import forStudents9 from "../assets/img/oneschool/Website/Images/forStudents9.jpg";
import forStudents10 from "../assets/img/oneschool/Website/Images/forStudents10.jpg";
import forStudents11 from "../assets/img/oneschool/Website/Images/forStudents11.jpg";
import forStudents12 from "../assets/img/oneschool/Website/Images/forStudents12.jpg";
import forStudents13 from "../assets/img/oneschool/Website/Images/forStudents13.jpg";
import forStudents14 from "../assets/img/oneschool/Website/Images/forStudents14.jpg";
import forStudents15 from "../assets/img/oneschool/Website/Images/forStudents15.jpg";
import forStudents16 from "../assets/img/oneschool/Website/Images/forStudents16.jpg";
import "./HomePageBottomSliderStyles.css";

const images = [
  {
    id: 0,
    name: "Windlesham House School",
    src: forStudents7,
  },
  {
    id: 1,
    name: "Westminster School",
    src: forStudents8,
  },
  {
    id: 2,
    name: "Brighton College",
    src: forStudents9,
  },
  {
    id: 3,
    name: "Downe House",
    src: forStudents10,
  },
  {
    id: 4,
    name: "Eton College",
    src: forStudents11,
  },
];

const iamges2 = [
  {
    id: 5,
    name: "Tonbridge School",
    src: forStudents12,
  },
  {
    id: 6,
    name: "Harrow School",
    src: forStudents13,
  },
  {
    id: 7,
    name: "Cheltenham Ladies' College",
    src: forStudents14,
  },
  {
    id: 8,
    name: "Aldro",
    src: forStudents15,
  },
  {
    id: 9,
    name: "Wycombe Abbey",
    src: forStudents16,
  },
];

const HomePageBottomSlider2 = () => {
  return (
    <div className="blue-bg-container text-black text-center">
      <div className="container py-5">
        <h1 className="styledHeading pt-5">
          Some of the schools where we have placed students
        </h1>
        <div className="home-bottom-slider pb-5 pt-5">
          <div className="row justify-content-center justify-content-md-between align-items-end pb-5">
            {images.map((image) => {
              return (
                <div key={image.id} className="col-8 col-sm-3 col-md-2 mb-5">
                  <div className="d-flex flex-column align-items-center">
                    <img
                      src={image.src}
                      className="img-fluid object-fit-contain mix-blend w-75"
                    />
                    <p className="m-0 mt-3 fw-semibold">{image.name}</p>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="row justify-content-center justify-content-md-between align-items-end">
            {iamges2.map((image) => {
              return (
                <div
                  key={image.id}
                  className="col-8 col-sm-3 col-md-2 mb-5 mb-sm-0 d-flex flex-column align-items-center justify-content-between"
                >
                  <img
                    src={image.src}
                    className="img-fluid object-fit-contain mix-blend w-75"
                  />
                  <p className="m-0 mt-3 fw-semibold">{image.name}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePageBottomSlider2;
