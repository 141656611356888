import { useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { BlogCardBlog } from "./BlogCardBlog";

export const BlogSectionBlog = ({
  blogs,
  tags,
  filteredBlogs,
  setFilteredBlogs,
  handleTagsFilter,
}) => {
  const [search, setSearch] = useState("");

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    const b = blogs.filter((blog) => {
      const blogTitle = blog.title.toLowerCase();
      if (blogTitle.includes(e.target.value)) {
        return blog;
      }
      if (!e.target.value) {
        return blogs;
      }
    });
    setFilteredBlogs(b);
  };

  const handleSearch = () => {
    const b = blogs.filter((blog) => {
      const blogTitle = blog.title.toLowerCase();
      if (blogTitle.includes(search)) {
        return blog;
      }
    });
    setFilteredBlogs(b);
  };

  const resetSearch = () => {
    setFilteredBlogs(blogs);
  };

  return (
    <div className="white-bg-container">
      <div className="container">
        <div className="blog-section">
          <div className="search-container">
            <div className="input-container">
              <AiOutlineSearch
                style={{
                  color: "#ccc",
                  fontSize: "30px",
                  fontWeight: 400,
                  marginInlineEnd: "10px",
                }}
              />
              <input
                type="text"
                placeholder="Search"
                className="search-input"
                onChange={handleSearchChange}
                value={search}
              />
            </div>
            <button
              className={
                filteredBlogs.length < blogs.length ? "reset-btn" : "search-btn"
              }
              onClick={
                filteredBlogs.length < blogs.length ? resetSearch : handleSearch
              }
            >
              {filteredBlogs.length < blogs.length ? "Reset" : "Search"}
            </button>
          </div>
          <div className="tag-container">
            {tags.map((tag) => {
              return (
                <button key={tag.id} className="tag" onClick={handleTagsFilter}>
                  <span>{tag.tag}</span>
                </button>
              );
            })}
          </div>
          {filteredBlogs.length > 0 ? (
            filteredBlogs.map((blog, i) => (
              <BlogCardBlog
                key={blog.blog_id}
                {...blog}
                i={i}
                handleTagsFilter={handleTagsFilter}
              />
            ))
          ) : (
            <h4 className="text-dark text-center">No Blogs Found</h4>
          )}
        </div>
      </div>
    </div>
  );
};
