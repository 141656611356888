import forStudents17 from "../assets/img/oneschool/Website/Images/forStudents17.jpg";
import forStudents18 from "../assets/img/oneschool/Website/Images/forStudents18.jpg";
import forStudents19 from "../assets/img/oneschool/Website/Images/forStudents19.jpg";
import forStudents20 from "../assets/img/oneschool/Website/Images/forStudents20.jpg";
import forStudents21 from "../assets/img/oneschool/Website/Images/forStudents21.jpg";
import forStudents22 from "../assets/img/oneschool/Website/Images/forStudents22.jpg";
import "./HomePageBottomSliderStyles.css";

const images = [
  {
    id: 0,
    src: forStudents17,
  },
  {
    id: 1,
    src: forStudents18,
  },
  {
    id: 2,
    src: forStudents19,
  },
];

const iamges2 = [
  {
    id: 5,
    src: forStudents20,
  },
  {
    id: 6,
    src: forStudents21,
  },
  {
    id: 7,
    src: forStudents22,
  },
];

const HomePageBottomSlider3 = () => {
  return (
    <div className="white-bg-container text-black text-center">
      <div className="container py-5">
        <h1 className="styledHeading pt-5">
          Some of the universities we have helped our students get into
        </h1>
        <div className="home-bottom-slider pb-5 pt-5 px-lg-5">
          <div className="row justify-content-center justify-content-md-between align-items-center pb-5 px-5">
            {images.map((image) => {
              return (
                <div
                  key={image.id}
                  className="col-8 col-sm-4 col-md-4 mb-5 mb-sm-0"
                >
                  <img
                    src={image.src}
                    className="img-fluid object-fit-contain mix-blend px-lg-5 w-75"
                  />
                </div>
              );
            })}
          </div>
          <div className="row justify-content-center justify-content-md-between align-items-center px-5 px-5">
            {iamges2.map((image) => {
              return (
                <div
                  key={image.id}
                  className="col-8 col-sm-4 col-md-4 mb-5 mb-sm-0"
                >
                  <img
                    src={image.src}
                    className="img-fluid object-fit-contain mix-blend px-lg-5 w-75"
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePageBottomSlider3;
