import ExperienceSummer from "./ExperienceSummer";
import { ContactSection } from "./components/ContactSection";
import HeroSection5 from "./components/HeroSection5";

const SummerCamp = () => {
  return (
    <div>
      <HeroSection5 />
      <ExperienceSummer />
    </div>
  );
};

export default SummerCamp;
