import { useEffect, useRef, useState } from "react";
import academicSupport from "../assets/img/oneschool/Website/Images/AcademicSupportImage.jpg";
import schoolAdmission from "../assets/img/oneschool/Website/Images/Schooladmissionimage.png";
import universityAdmissionsImage from "../assets/img/oneschool/Website/Images/Univeristyadmissionimage.png";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Link } from "react-router-dom";

export const ServicesSection = () => {
  const images = [academicSupport, schoolAdmission, universityAdmissionsImage];
  const [activeImageIndex, setActiveImageIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setActiveImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const animateTurn = useRef(0);

  useEffect(() => {
    const interval = setInterval(() => {
      const blueBars = document.querySelectorAll("#blue-bar-animation");

      blueBars.forEach((item, i) => {
        const classExists = item.classList.contains("service-bar-blue");

        if (classExists) {
          item.classList.remove("service-bar-blue");
        }

        if (!classExists && animateTurn.current === i) {
          item.classList.add("service-bar-blue");
        }
      });

      animateTurn.current = (animateTurn.current + 1) % blueBars.length;
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="white-bg-container">
      <section className="services-section">
        <div className="services-content">
          <h2>SERVICES</h2>
          <h1>
            We provide a range of
            <span className="highlight"> personalised</span> services to
            students
          </h1>
          <div className="mb-4 mb-sm-0">
            <div className="service-detail">
              <div className="services-bar"></div>
              <div id="blue-bar-animation" className="service-bar-blue"></div>
              <h3>Tutoring</h3>
              <p>
                We offer students personalised one-to-one and group classes
                across the entire UK national curriculum, including bespoke
                enrichment courses and revision, delivered by highly-qualified
                and trained teachers
              </p>
              <Link to="/subject-tutoring">
                <button className="d-none d-sm-block service-detail-button">
                  Learn more
                </button>
              </Link>
            </div>
            <Link to="/subject-tutoring">
              <button className="d-block d-sm-none service-detail-button">
                Learn more
              </button>
            </Link>
          </div>
          <div className="services-image services-image-conditional">
            <TransitionGroup component={null}>
              <CSSTransition
                key={activeImageIndex}
                timeout={1000}
                classNames="fade"
              >
                <img src={images[activeImageIndex]} alt="Services images" />
              </CSSTransition>
            </TransitionGroup>
          </div>
          <div className="mb-4 mb-sm-0">
            <div className="service-detail">
              <div className="services-bar"></div>
              <div id="blue-bar-animation" className=""></div>
              <h3>School and university admissions</h3>
              <p>
                We act as educational mentors to students aiming to study in the
                UK and USA, and support them as guardians once they start school
              </p>
            </div>
          </div>
          <div className="mb-4 mb-sm-0">
            <div className="service-detail">
              <div className="services-bar"></div>
              <div id="blue-bar-animation" className=""></div>
              <h3>Residential / summer camps</h3>
              <p>
                We work with partners across the UK to provide students with a
                summer experience tailored to their personality and interests
              </p>
              <Link to="/summer-camp">
                <button className="d-none d-sm-block service-detail-button">
                  Learn more
                </button>
              </Link>
            </div>
            <Link to="/summer-camp">
              <button className="d-block d-sm-none service-detail-button">
                Learn more
              </button>
            </Link>
          </div>
          <div className="mb-4 mb-sm-0">
            <div className="service-detail">
              <div className="services-bar"></div>
              <div id="blue-bar-animation" className=""></div>
              <h3>English Language Training</h3>
              <p>
                We run both regular and intensive English Language Training
                classes for adult and child learners, from beginner to advanced
                level
              </p>
              <Link to="/english-language">
                <button className="d-none d-sm-block service-detail-button">
                  Learn more
                </button>
              </Link>
            </div>
            <Link to="/english-language">
              <button className="d-block d-sm-none service-detail-button">
                Learn more
              </button>
            </Link>
          </div>
        </div>
        <div className="services-image services-image-conditional-2">
          <TransitionGroup component={null}>
            <CSSTransition
              key={activeImageIndex}
              timeout={1000}
              classNames="fade"
            >
              <img src={images[activeImageIndex]} alt="Services images" />
            </CSSTransition>
          </TransitionGroup>
        </div>
      </section>
    </div>
  );
};
