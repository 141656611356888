import "./HomePage.css";
import "./UniversityAdmissions.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-circular-progressbar/dist/styles.css";
import { ContactSection } from "./ContactSection";
import { HeroSectionUA } from "./HeroSectionUA";
import { ServicesSectionUniversityUA } from "./ServicesSectionUniversityUA";
import { TimelineUniversityUA } from "./TimelineUniversityUA";
import { SuccessStoryUniversityUA } from "./SuccessStoryUniversityUA";

const UniversityAdmissions = () => {
  return (
    <div>
      <HeroSectionUA />
      <ServicesSectionUniversityUA />
      <TimelineUniversityUA />
      <SuccessStoryUniversityUA />
    </div>
  );
};

export default UniversityAdmissions;
