import "./SdgSectionStyles.css";

const SdgSection = () => {
  return (
    <div className="sdg-section white-bg-container text-dark text-center">
      <div className="container py-5 px-2 px-md-5">
        <h3 className="mb-4">
          We are aligned to the UN's Sustainable Development Goal (SDG4):
        </h3>
        <h1 className="sdg-colored-heading px-2 px-md-5">
          "Ensure inclusive and equitable quality education and promote lifelong
          learning opportunities for all"
        </h1>
      </div>
    </div>
  );
};

export default SdgSection;
