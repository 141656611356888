import starSquareFilled from "../assets/img/oneschool/Website/Images/starSquareFilled.png";
import scholarSquareFilled from "../assets/img/oneschool/Website/Images/scholarSquareFilled.png";
import globeSquareFilled from "../assets/img/oneschool/Website/Images/globeSquareFilled.png";
import scholarHatSquareFilled from "../assets/img/oneschool/Website/Images/scholarHarSquareFilled.png";
import "./ExcellenceInEducationSectionStyles.css";

const iconBoxItems = [
  {
    id: 0,
    img: starSquareFilled,
    title1: "Pursuit of",
    title2: "Excellence",
    content: "Holistic academic support throughout a student's education",
  },
  {
    id: 1,
    img: scholarSquareFilled,
    title1: "Empowering",
    title2: "Students",
    content:
      "Equip pupils with the skills and tools to thrive in education & beyond",
  },
  {
    id: 2,
    img: globeSquareFilled,
    title1: "Global",
    title2: "Reach",
    content: "State of the art technology enables access to education globally",
  },
  {
    id: 3,
    img: scholarHatSquareFilled,
    title1: "World-class",
    title2: "curriculum",
    content:
      "UK National curriculum is the base for all lessons & academic preparation",
  },
];

const ExcellenceInEducationSection = () => {
  return (
    <div className="light-blue-bg-container">
      <div className="container text-dark">
        <section className="py-5 text-center">
          <h1 className="colored-section-title fw-semibold">
            <span>Excellence</span> in Education
          </h1>
          <div className="icon-box">
            <div className="row">
              {iconBoxItems.map((item) => {
                return (
                  <div key={item.id} className="col-12 col-md-6 col-lg-3">
                    <div className="icon-box d-flex flex-column align-items-center px-4 mb-5 mb-xxl-0">
                      <img
                        src={item.img}
                        className="img-fluid object-fit-contain w-25"
                      />
                      <h4>{item.title1} <br/> {item.title2} </h4>
                      <p>{item.content}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ExcellenceInEducationSection;
