import Accordion from "react-bootstrap/Accordion";
import './MyAccordainStyles.css'

function MyAccordain({ content }) {
  return (
    <Accordion defaultActiveKey="0" flush>
      {content.map((c) => {
        return (
          <Accordion.Item eventKey={c.id.toString()}>
            <Accordion.Header >{c.title}</Accordion.Header>
            <Accordion.Body>{c.content}</Accordion.Body>
          </Accordion.Item>
        );
      })}
    </Accordion>
  );
}

export default MyAccordain;
