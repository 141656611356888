import { Link } from "react-router-dom";
import "./HeroCompStyles.css";

const HeroComp = ({ heading, para }) => {
  return (
    <div className="for-students-hero d-flex justify-content-center align-items-center">
      {/* <img className="img-fluid hero-image" src={image} /> */}
      <div className="text-center px-4">
        <h1 className="mb-3 fw-semibold">{heading}</h1>
        <p className="mb-3 fs-5">{para}</p>
        <button className="inquire-button ">
          <Link to="/enquire" className="text-white">
            Enquire Now
            </Link>
        </button>
      </div>
    </div>
  );
};

export default HeroComp;
