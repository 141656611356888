import axios from "axios";
import { useState } from "react";
import { baseUrl } from "./constants";
import { Alert } from "react-bootstrap";
import { useLocation } from "react-router-dom";

export const ContactSection = () => {
  const { pathname } = useLocation();
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    choices: "",
    message: "",
  });

  const [formMessage, setFormMessage] = useState({
    success: false,
    message: "",
  });
  const [showMessage, setShowMessage] = useState(false);

  // Handle input changes
  const handleChange = (e) => {
    console.log(e.target.value);
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    const { first_name, last_name, email, choices, message } = e.target;
    e.preventDefault();
    // Submit form data to a server or email
    // alert("Form submitted!"); // Placeholder for actual submit logic
    if ((first_name, last_name, email, choices, message)) {
      console.log(formData);
      axios
        .post(`${baseUrl}/inquiry/`, formData)
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            setFormData({
              first_name: "",
              last_name: "",
              email: "",
              choices: "",
              message: "",
            });
            setFormMessage({ success: true, message: res.data.message });
            setShowMessage(true);
            setTimeout(function () {
              setShowMessage(false);
            }, 5000);
          }
        })
        .catch((err) => {
          console.log(err);
          // setFormData({
          //   first_name: "",
          //   last_name: "",
          //   email: "",
          //   choices: "",
          //   message: "",
          // });
          setFormMessage({
            success: false,
            message: "An error occured, please try again.",
          });
          setShowMessage(true);
          setTimeout(function () {
            setShowMessage(false);
          }, 5000);
        });
    } else {
      alert("please fill form");
    }
  };

  const foldedCornerSVG = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="205"
      height="196"
      viewBox="0 0 204 196"
      fill="none"
    >
      <path
        d="M-458.773 608.039H142.309C175.286 608.039 202.019 581.305 202.019 548.329V182.053C202.019 168.2 197.202 154.779 188.395 144.087L89.2433 23.7256C77.9008 9.95688 60.996 1.98071 43.1569 1.98071L-458.773 1.98073C-491.75 1.98073 -518.483 28.7139 -518.483 61.6908V548.329C-518.483 581.305 -491.75 608.039 -458.773 608.039Z"
        fill="white"
        stroke="#0ABAB5"
        strokeWidth="1.499"
      />
    </svg>
  );

  const cornerGap = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="206"
      height="197"
      viewBox="0 0 206 197"
      fill="none"
    >
      <path
        d="M0 3.5V0H203H203.5H205.5L205 197H202.5C204.1 166.6 195 153 192.5 149.5L120 61.5C117.5 60.5 92.5 27 84 19C75.5 11 67.5 8.5 62 6C57.6 4 53.5 3.5 52 3.5H0Z"
        fill="white"
      />
    </svg>
  );

  return (
    <div
      className="white-bg-container"
      style={
        pathname === "/privacy-policy"
          ? { display: "none" }
          : { display: "block" }
      }
    >
      <div className="contact-container">
        <div className="contact-heading">
          <h1>CONTACT US</h1>
          <h2>Your child’s success is just a message away</h2>
        </div>
        <div className="contact-form-container">
          <div className="folded-corner">{foldedCornerSVG}</div>
          <div className="corner-gap">{cornerGap}</div>
          <div className="contact-form-heading">
            <h3>Let's talk.</h3>
            <p>
              Have a question or want to learn more about our services? Contact
              us today, we will be happy to assist you.
            </p>
          </div>
          <form onSubmit={handleSubmit} className="contact-form pt-2">
            <div className="row">
              <div className="col-12 col-sm-6">
                <input
                  type="text"
                  name="first_name"
                  placeholder="First Name"
                  value={formData.first_name}
                  onChange={handleChange}
                  required
                  className="w-100"
                />
              </div>
              <div className="col-12 col-sm-6">
                <input
                  type="text"
                  name="last_name"
                  placeholder="Last Name"
                  value={formData.last_name}
                  onChange={handleChange}
                  required
                  className="w-100"
                />
              </div>
              <div className="col-12">
                <input
                  type="email"
                  name="email"
                  placeholder="Email address"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  className="w-100"
                />
              </div>
              <div className="col-12">
                <select
                  className="w-100"
                  name="choices"
                  onChange={handleChange}
                  required
                >
                  <option disabled selected>
                    I am 'select choice'
                  </option>
                  <option value="student">I am a student</option>
                  <option value="parent">I am a parent</option>
                  <option value="school">I am a school</option>
                </select>
              </div>
              <div className="col-12">
                <textarea
                  name="message"
                  placeholder="Write a message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                  className="w-100"
                ></textarea>
              </div>
              <div className="col-12 d-flex justify-content-end">
                <button type="submit">Submit</button>
              </div>
            </div>
            {showMessage ? (
              <Alert variant={formMessage.success ? "success" : "warning"}>
                {formMessage.message}
              </Alert>
            ) : null}
          </form>
        </div>
      </div>
    </div>
  );
};
