import "./HomePage.css";
import "./Tutoring.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-circular-progressbar/dist/styles.css";
import { ContactSection } from "./ContactSection";
import { HeroSectionTutoring } from "./HeroSectionTutoring";
import { PrivateTutoringService } from "./PrivateTutoringService";
import { OneSchoolAcademy } from "./OneSchoolAcademy";
import { SubjectsSection } from "./SubjectsSection";
import { SuccessStoryTutoring } from "./SuccessStoryTutoring";
import { MeetOurTeachersTutoring } from "./MeetOurTeachersTutoring";

const Tutoring = () => {
  return (
    <div>
      <HeroSectionTutoring />
      <PrivateTutoringService />
      <OneSchoolAcademy />
      <SubjectsSection />
      <SuccessStoryTutoring />
      <MeetOurTeachersTutoring />
    </div>
  );
};

export default Tutoring;
