import aliKhan1 from "../assets/img/oneschool/Website/Images/AliKhanimage1.png";
import ellipseGroupImage from "../assets/img/oneschool/Website/Images/ellipse_group.png";
import aliKhan1Grouped from "../assets/img/oneschool/Website/Images/AliKhanimage1_grouped.png";
import aliKhan2 from "../assets/img/oneschool/Website/Images/AliKhanimage2.jpg";
import aliKhan2Grouped from "../assets/img/oneschool/Website/Images/AliKhanimage2_grouped.png";
import aliKhan3 from "../assets/img/oneschool/Website/Images/AliKhanimage3.jpg";
import aliKhan3Grouped from "../assets/img/oneschool/Website/Images/AliKhanimage3_grouped.png";
import aliKhan4 from "../assets/img/oneschool/Website/Images/AliKhanimage4.jpg";
import aliKhan4Grouped from "../assets/img/oneschool/Website/Images/AliKhanimage4_grouped.png";

export const SuccessStoryUniversityUA = () => {
  return (
    <div className="blue-bg-container">
      <div className="success-story">
        <h2>One of success story</h2>
        <h3>
          Ali Khan journey from Harrow Beijing to TonBridge to Oxford, London
        </h3>
        <div className="luke-story-content">
          <div className="about-luke">
            <h4>About Ali Khan</h4>
            <p>
              Ali Khan was a good student at Harrow Beijing with an interest in
              Maths. Alikhan's journey to a school in the UK was initiated by
              his parents, but after touring the schools in person, the dream
              became very much his own. Seeing the students in action, seeing
              what schools and education could be, and seeing himself as a
              student there inspired and motivated him to persist in the
              challenging journey ahead. However, the competitive nature of UK
              schools meant he had to understand the level required was not the
              one he was used to. He had to tackle motivational issues, and
              manage a demanding schedule. Balancing self-study with additional
              tutoring and personal interests, all on top of his regular school
              schedule was paramount.
            </p>
          </div>
          <div className="luke-image-container">
            <img
              src={aliKhan1}
              alt="Luke"
              className="luke-image aliKhan-image-desktop"
            />
            <img
              src={ellipseGroupImage}
              alt="Decorative"
              className="decorative-ellipse-luke-top"
            />
            <img
              src={ellipseGroupImage}
              alt="Decorative"
              className="decorative-ellipse-luke-bottom"
            />
            <img
              src={aliKhan1Grouped}
              alt="Luke"
              className="aliKhan-image-grouped"
            />
          </div>
        </div>
        <div className="luke-flipped-story-content">
          <div className="luke-flipped-image-container">
            <img
              src={aliKhan2}
              alt="Luke"
              className="luke-flipped-image aliKhan-image-desktop"
            />
            <img
              src={ellipseGroupImage}
              alt="Decorative"
              className="decorative-ellipse-luke-top-flipped"
            />
            <img
              src={ellipseGroupImage}
              alt="Decorative"
              className="decorative-ellipse-luke-bottom-flipped"
            />
            <img
              src={aliKhan2Grouped}
              alt="Luke"
              className="aliKhan-image-grouped"
            />
          </div>
          <div className="about-luke-flipped">
            <h4>One school role in Ali Khan journey</h4>
            <p>
              Our role was multifaceted. We had to convince Alikhan’s parents
              that the UK was an ideal fit for him, then we mapped out his
              academic journey, provided the necessary tutors and resources, and
              offered unwavering guidance, both for his Tonbridge admissions,
              and all the way through to when he was applying to Oxford.
              Usually, this is a 3-year preparation journey, however Alikhan
              only had a year to prepare, so his schedule was exceptionally
              demanding.
            </p>
          </div>
        </div>
        <div className="luke-story-content">
          <div className="about-luke">
            <h4>Impact on Ali Khan life after gaining UK education</h4>
            <p>
              We’ve kept tabs on Ali Khan’s journey at Tonbridge, providing
              guidance and support when necessary. Once in the new environment,
              Alikhan's academic growth was spectacular, where he achieved
              outstanding results in his GCSEs and A-levels. But the most
              significant transformation has been in his demeanour. The
              once-reticent Alikhan is now confident, and independent.
              Ultimately this is what has led to his admission to Oxford
              University, where he is studying Maths & Computer Science.
            </p>
          </div>
          <div className="luke-image-container">
            <img
              src={aliKhan3}
              alt="Luke"
              className="luke-image aliKhan-image-desktop"
            />
            <img
              src={ellipseGroupImage}
              alt="Decorative"
              className="decorative-ellipse-luke-top"
            />
            <img
              src={ellipseGroupImage}
              alt="Decorative"
              className="decorative-ellipse-luke-bottom"
            />
            <img
              src={aliKhan3Grouped}
              alt="Luke"
              className="aliKhan-image-grouped"
            />
          </div>
        </div>
        <div className="luke-flipped-story-content">
          <div className="luke-flipped-image-container">
            <img
              src={aliKhan4}
              alt="Luke"
              className="luke-flipped-image aliKhan-image-desktop"
            />
            <img
              src={ellipseGroupImage}
              alt="Decorative"
              className="decorative-ellipse-luke-top-flipped"
            />
            <img
              src={ellipseGroupImage}
              alt="Decorative"
              className="decorative-ellipse-luke-bottom-flipped"
            />
            <img
              src={aliKhan4Grouped}
              alt="Luke"
              className="aliKhan-image-grouped"
            />
          </div>
          <div className="about-luke-flipped">
            <h4>Insights gained in Ali Khan journey</h4>
            <p>
              Ali Khan's story underscores that with the right structure,
              guidance, and dedication, the loftiest of dreams are achievable.
              It highlights how important it is to visit schools early so that
              children understand what they are working towards, to have an
              academic plan from start to finish, and have someone to oversee
              the student’s progress, and offer external encouragement and
              support as and when needed.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
