import { Link } from "react-router-dom";
import "./ImageLeftCompStyles.css";

const ImageLeftComp = ({ isReverse, content }) => {
  console.log(content.buttons);
  return (
    <div
      className={
        !isReverse
          ? "blue-bg-container text-black"
          : "white-bg-container text-black"
      }
    >
      <div className="for-students-2 container p-md-5">
        <div className="row align-items-center">
          {!isReverse ? (
            <>
              <div className="col-lg-6 col-sm-12 p-5 d-flex justify-content-end">
                <img src={content.image} className="img-fluid" />
              </div>
              <div className="col-lg-6 col-sm-12 p-5">
                <h3 className="section-heading">{content.heading1}</h3>
                <h6 className="section-heading-2">{content.heading2}</h6>
                <p className="section-heading-content">{content.content}</p>
                {content.buttons.length > 0 &&
                  content.buttons.map((button) => {
                    return (
                      <Link to={button.link}>
                        <button
                          className={
                            button.type === "border"
                              ? "section-button-1"
                              : "section-button-2"
                          }
                        >
                          {button.content}
                        </button>
                      </Link>
                    );
                  })}
              </div>
            </>
          ) : (
            <>
              <div className="col-lg-6 col-sm-12 p-5">
                <h3 className="section-heading">{content.heading1}</h3>
                <h6 className="section-heading-2">{content.heading2}</h6>
                <p className="section-heading-content">{content.content}</p>
                {content.buttons.length > 0 &&
                  content.buttons.map((button) => {
                    return (
                      <Link to={button.link}>
                        <button
                          className={
                            button.type === "border"
                              ? "section-button-1"
                              : "section-button-2"
                          }
                        >
                          {button.content}
                        </button>
                      </Link>
                    );
                  })}
              </div>
              <div className="col-lg-6 col-sm-12 p-5 d-flex justify-content-end">
                <img src={content.image} className="img-fluid" />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ImageLeftComp;
