import image1 from "../assets/img/oneschool/Website/Images/weSupportStudents1.png";
import './WeSupportStudentsAboutusStyles.css'

const WeSupportStudentsAboutus = () => {
  return (
    <div className="blue-bg-container text-black">
      <div className="container py-5 w-md-75">
        <div className="we-support-section text-center">
          <h1>We support students around the world</h1>
          <p className="text-center mx-md-5">
            Our students and teachers come from all around the world, and
            through the power of online learning, we are able to make a high
            quality education accessible to all
          </p>
          <img src={image1} className="img-fluid" />
        </div>
      </div>
    </div>
  );
};

export default WeSupportStudentsAboutus;
