import ImageLeftCom2 from "./ImageLeftComp2";
import HeroSection2 from "./components/HeroSection2";
import st2 from "./assets/img/oneschool/Website/Images/st2.png";
import st3 from "./assets/img/oneschool/Website/Images/st3.png";
import st4 from "./assets/img/oneschool/Website/Images/st4.png";
import StHowItWorks from "./StHowItWorks";
import TeachersHighlyQualified2 from "./components/TeachersHighlyQualified2";
import { ResultsSection2 } from "./ResultsSection2";
import { Testimonials2 } from "./components/Testimonials2";

const content = [
  {
    id: 0,
    image: st2,
    heading1: "Create good habits",
    content:
      "Learn how to learn, develop discipline and focus, and build confidence to overcome challenges, both in and out of the classroom",
  },
  {
    id: 1,
    image: st3,
    heading1: "Develop subject mastery",
    content:
      "With an emphasis on critical thinking and a questioning mindset, we make sure that students don't just memorise facts, but understand and internalise their material",
  },
  {
    id: 2,
    image: st4,
    heading1: "Take charge of your learning",
    content:
      "At One School, our students take ownership of their learning journey, and active participation is always encouraged",
  }
]

const SubjectTutoring = () => {
  return (
    <div>
      <HeroSection2 />
      {content.map(c=>{
        return c.id % 2 === 0 ? <ImageLeftCom2 isReverse={false} content={c} /> : <ImageLeftCom2 isReverse={true} content={c} />
      })}      
      <StHowItWorks />
      <TeachersHighlyQualified2 />
      <ResultsSection2 bg="white" />
      <Testimonials2 />
    </div>
  );
};

export default SubjectTutoring;
