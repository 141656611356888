import "./StHowItWorksStyles.css";

const content = [
  {
    id: 1,
    align: "right",
    heading: "Get in touch",
    content:
      "You’ll get to know us, and we’ll work with you to understand your goals",
  },
  {
    id: 2,
    align: "left",
    heading: "Take a placement test",
    content:
      "You take a comprehensive placement test with us designed to identify your strengths and help us tailor your learning journey to your goals",
  },
  {
    id: 3,
    align: "right",
    heading: "Start lessons",
    content:
      "You’ll be matched with a teacher suited to your profile, and start lessons to work towards your goals",
  },
  {
    id: 4,
    align: "left",
    heading: "Lesson Reports",
    content:
      "You get feedback after every lesson to help you track progress and highlight areas for improvement ",
  },
  {
    id: 5,
    align: "right",
    heading: "Homework",
    content:
      "We help you reinforce your learning and understanding through homework assignments after every lesson",
  },
  {
    id: 6,
    align: "left",
    heading: "Monthly reports",
    content:
      "We provide detailed academic progress reports monthly, which offer insights into a students' achievements and highlight areas for improvement",
  },
  {
    id: 7,
    align: "right",
    heading: "Mid-term test",
    content:
      "We give you a checkpoint test, assessing your mastery of the curriculum so far, and guiding your future learning journey",
  },
  {
    id: 8,
    align: "left",
    heading: "End-of-term test",
    content:
      "We give you a comprehensive exam designed to evaluate the cumulative knowledge and skills acquired, ensuring readiness for the next learning phase",
  },
  {
    id: 9,
    align: "right",
    heading: "End-of-year transcript",
    content:
      "We give you a detailed record of your academic journey over the year, reflecting achievements and readiness for future academic challenges",
  },
];
const StHowItWorks = () => {
  return (
    <div className="white-bg-container text-black">
      <div className="container p-md-5 position-relative">
        <h1 className="text-center mb-5">How it works</h1>
        <div className="w-sm-75 ms-5 mx-sm-auto">
          <div className="middle-line-yellow"></div>
          <div className="middle-line-blue"></div>
          <div className="st-how-it-works row py-5 w-lg-75 mx-auto">
            {content.map((c) => {
              if (c.align === "right") {
                return (
                  <>
                    <div className="col-sm-5"></div>
                    <div className="col-sm-2"></div>
                    <div className="col-sm-5 d-flex flex-column">
                      <h4 className="align-self-start py-3 px-4 rounded-circle text-white">
                        {c.id}
                      </h4>
                      <h2>{c.heading}</h2>
                      <p className="w-md-50">{c.content}</p>
                    </div>
                  </>
                );
              }
              if (c.align === "left") {
                return (
                  <>
                    <div className="col-sm-5 d-flex flex-column ">
                      <h4 className="align-self-start py-3 px-4 rounded-circle text-white">
                        {c.id}
                      </h4>
                      <h2>{c.heading}</h2>
                      <p className="w-md-50">{c.content}</p>
                    </div>
                    <div className="col-sm-2"></div>
                    <div className="col-sm-5"></div>
                  </>
                );
              }
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StHowItWorks;
