// import './HomePage.css';
import "./SchoolAdmissions.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "react-circular-progressbar/dist/styles.css";

import { ContactSection } from "./ContactSection";
import { HeroSectionSA } from "./HeroSectionSA";
import { ServicesSectionBoarding } from "./ServicesSectionBoarding";
import { Timeline } from "./Timeline";
import { SuccessStorySA } from "./SuccessStorySA";

const SchoolAdmissions = () => {
  return (
    <div>
      <HeroSectionSA />
      <ServicesSectionBoarding />
      <Timeline />
      <SuccessStorySA />
    </div>
  );
};

export default SchoolAdmissions;
