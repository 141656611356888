import "./TeamOfEducatorsStyles.css";

const team = [
  {
    id: 0,
    row: 1,
    content: [
      { id: 0, name: "Khurram", designation: "Co-Founder" },
      { id: 1, name: "Shahryar", designation: "Co-Founder" },
      { id: 2, name: "Theo", designation: "Technology" },
      { id: 3, name: "Harvey", designation: "Technology" },
    ],
  },
  {
    id: 0,
    row: 1,
    content: [
      { id: 4, name: "Mortimer", designation: "Academic Operations" },
      { id: 5, name: "Khusrau", designation: "Academic Operations" },
      { id: 6, name: "Leia", designation: "Consultant" },
      { id: 7, name: "Imogen", designation: "Education Opportunities" },
    ],
  },
  {
    id: 0,
    row: 1,
    content: [
      { id: 8, name: "Daniel", designation: "Quality of Education" },
      { id: 9, name: "Harry", designation: "AI / Technology" },
      { id: 10, name: "Rebecca", designation: "Academic Consultant" },
    ],
  },
];

const TeamOfEducators = () => {
  return (
    <div className="white-bg-container text-black">
      <div className="team-of-educators container text-center py-5">
        <h1 className="my-5">Our expert team of educators</h1>
        {team.map((row) => {
          return (
            <div key={row.id} className="row mb-0 mb-sm-5">
              {row.content.map((t) => {
                return (
                  <div key={t.id} className="col-12 col-sm-3 mb-2 mb-sm-0">
                    <h5>{t.name}</h5>
                    <p>{t.designation}</p>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TeamOfEducators;
