import React, { useState, useEffect } from "react";
import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import { FaBars } from "react-icons/fa";
import logo from "../assets/img/oneschool/Website/Icons/OneSchoolFullIcon.svg";
import { useLocation, Link, useNavigate } from "react-router-dom";
import "./NavBar.css";

export const NavBar = () => {
  const [expanded, setExpanded] = useState(false);
  const [showForStudents, setShowForStudents] = useState(false);
  const [showForSchools, setShowForSchools] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, [location.pathname]);

  useEffect(() => {
    setExpanded(false);
  }, [location]);

  const handleMainLinkClick = (path, event) => {
    if (event.target.tagName === "SPAN") {
      setExpanded(false);
      navigate(path);
    }
  };

  const handleDropdownToggle = (setShow) => {
    setShow((prev) => !prev);
  };

  return (
    <Navbar
      expanded={expanded}
      expand="md"
      onToggle={() => setExpanded(!expanded)}
      className="position-sticky top-0"
    >
      <Container>
        <Navbar.Brand to="/" as={Link}>
          <img src={logo} alt="Logo" className="logo" />
        </Navbar.Brand>
        <Navbar.Toggle
          className="remove-border"
          aria-controls="responsive-navbar-nav"
        >
          <FaBars size={25} color="#403838" />
        </Navbar.Toggle>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto d-none d-md-flex nav-align-center textcolor">
            <Nav.Link
              as={Link}
              to="/"
              className={
                location.pathname === "/" ? "active navbar-link" : "navbar-link"
              }
            >
              Home
            </Nav.Link>
            <NavDropdown
              title={<span onClick={(e) => handleMainLinkClick("/for-students", e)}>For Students</span>}
              id="basic-nav-dropdown"
              show={showForStudents}
              onMouseEnter={() => setShowForStudents(true)}
              onMouseLeave={() => setShowForStudents(false)}
            >
              <NavDropdown.Item as={Link} to="/online-school">
                Online School
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/subject-tutoring">
                Subject Tutoring
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/summer-camp">
                Residential & Day camps
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/english-language">
                English Language
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown
              title={<span onClick={(e) => handleMainLinkClick("/for-schools", e)}>For Schools</span>}
              id="basic-nav-dropdown"
              show={showForSchools}
              onMouseEnter={() => setShowForSchools(true)}
              onMouseLeave={() => setShowForSchools(false)}
            >
              <NavDropdown.Item as={Link} to="/online-school">
                Accredited curriculum
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/accelerator-courses">
                University admissions
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/summer-camp">
                Holiday camps
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link
              as={Link}
              to="/about-us"
              className={
                location.pathname === "/about-us"
                  ? "active navbar-link"
                  : "navbar-link"
              }
            >
              About us
            </Nav.Link>
            <Nav.Link as={Link} to="/enquire" className="inquire-text">
              Enquire Now
            </Nav.Link>
          </Nav>
          <Nav className="ms-auto d-md-none textcolor">
            <Nav.Link as={Link} to="/" style={{ color: "#333232" }}>
              Home
            </Nav.Link>
            <NavDropdown
              title={<span onClick={(e) => handleMainLinkClick("/for-students", e)}>For Students</span>}
              id="basic-nav-dropdown"
              show={showForStudents}
              onToggle={() => handleDropdownToggle(setShowForStudents)}
              className="pb-2"
            >
              <NavDropdown.Item
                as={Link}
                to="/online-school"
                onClick={() => setExpanded(false)}
              >
                Online School
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to="/subject-tutoring"
                onClick={() => setExpanded(false)}
              >
                Subject Tutoring
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to="/summer-camp"
                onClick={() => setExpanded(false)}
              >
                Residential & Day camps
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to="/english-language"
                onClick={() => setExpanded(false)}
              >
                English Language
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown
              title={<span onClick={(e) => handleMainLinkClick("/for-schools", e)}>For Schools</span>}
              id="basic-nav-dropdown"
              show={showForSchools}
              onToggle={() => handleDropdownToggle(setShowForSchools)}
            >
              <NavDropdown.Item
                as={Link}
                to="/online-school"
                onClick={() => setExpanded(false)}
              >
                Accredited curriculum
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to="/accelerator-courses"
                onClick={() => setExpanded(false)}
              >
                University admissions
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to="/summer-camp"
                onClick={() => setExpanded(false)}
              >
                Holiday camps
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link as={Link} to="/about-us" style={{ color: "#333232" }}>
              About us
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
