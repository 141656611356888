import "./HomePage.css";
import "./AboutUs.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-circular-progressbar/dist/styles.css";
import { HeroSectionAbout } from "./HeroSectionAbout";
import { OurJourneyAbout } from "./OurJourneyAbout";
import SdgSection from "./SdgSection";
import DedicationSectionAboutUs from "./DedicationSectionAboutUs";
import OurStoryAboutUs from "./OurStoryAboutUs";
import OurPhilosophyAboutUs from "./OurPhilosophyAboutUs";
import WeSupportStudentsAboutus from "./WeSupportStudentsAboutus";
import TeamOfEducators from "./TeamOfEducators";
import TeachersHighlyQualified from "./TeachersHighlyQualified";

const AboutUs = () => {
  return (
    <div>
      <HeroSectionAbout />
      <SdgSection />
      <DedicationSectionAboutUs />
      <OurStoryAboutUs />
      <OurPhilosophyAboutUs />
      <OurJourneyAbout />
      <WeSupportStudentsAboutus />
      <TeamOfEducators />
      <TeachersHighlyQualified />
    </div>
  );
};

export default AboutUs;
