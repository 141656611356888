import ellipseGroupImage from "../assets/img/oneschool/Website/Images/ellipse_group.png";
import aboutUsImage2_crop from "../assets/img/oneschool/Website/Images/aboutusimage2_crop.png";
import aboutUsImage2_mobile from "../assets/img/oneschool/Website/Images/aboutusimage2_mobile.png";

export const PrivateTutoringService = () => {
  return (
    <div className="blue-bg-container">
      <section className="private-tutoring">
        <div className="ellipse-group-tutor-top">
          <img src={ellipseGroupImage} alt="Decorative Ellipse" />
        </div>
        <div className="ellipse-group-tutor-bottom">
          <img src={ellipseGroupImage} alt="Decorative Ellipse" />
        </div>
        <div className="tutoring-content">
          <div className="image-container-private">
            <img
              src={aboutUsImage2_crop}
              alt="Tutoring"
              className="aboutUsImage-normal"
            />
            <img
              src={aboutUsImage2_mobile}
              alt="Grouped images"
              className="aboutUsImage-mobile"
            />
          </div>
          <div className="text-content-tutor">
            <h2>Private tutoring service</h2>
            <p>
              We create personalized tutoring plans for each student based on
              their level and needs, covering areas such as improving English
              proficiency, subject tutoring, exam preparation, and in-school
              academic support. Our goal is to help students achieve short-term
              and long-term academic goals and lay a solid foundation for their
              future success.
            </p>
            <ul className="features-list">
              <li>
                <span className="number">1</span> Expert academic team
                <p className="subtext">
                  End to End Business Platform, Sales Management, Marketing
                  Automation, Help Desk and many more
                </p>
              </li>
              <li>
                <span className="number">2</span> Experienced UK teachers
                <p className="subtext">
                  We have a team of experienced and qualified British teachers
                  who provide the strongest academic support to students.
                </p>
              </li>
              <li>
                <span className="number">3</span> Personalised approach
                <p className="subtext">
                  We employ a systematic and personalised approach to meet the
                  unique needs of each student. We develop short-term and
                  long-term plans to help students achieve their academic goals.
                </p>
              </li>
              <li>
                <span className="number">4</span> Guidance counsellors
                <p className="subtext">
                  We employ a systematic and personalised approach to meet the
                  unique needs of each student. We develop short-term and
                  long-term plans to help students achieve their academic goals.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
};
