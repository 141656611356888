import { Link } from "react-router-dom";
import sc2 from "./assets/img/oneschool/Website/Images/sc2.png";
import sc3 from "./assets/img/oneschool/Website/Images/sc3.png";
import sc4 from "./assets/img/oneschool/Website/Images/sc4.png";
import "./ExperienceSummerStyles.css";

const ExperienceSummer = () => {
  return (
    <div className="blue-bg-container text-black py-5">
      <div className="container py-5">
        <div className="exp-summer">
          <div className="row">
            <div className="col-12 col-md-6 mb-5 mb-md-0">
              <h1 className="mb-4">Experience an unforgettable summer!</h1>
              <p className="mb-4">
                Meet people from all over the world while learning what life in
                the UK is like!
              </p>
              <Link to='/enquire'>
              <button className="inquire-button text-white">Enroll Now</button>
              </Link>
            </div>
            <div className="col-12 col-md-6">
              <div className="row mb-0 mb-sm-5">
                <div className="col-12 col-sm-6">
                  <h3>Pre-University experiences</h3>
                  <p>
                    Spend two weeks at Oxford or Cambridge, exploring your
                    chosen subject in a university setting and hearing from
                    subject specialists and industry professionals
                  </p>
                </div>
                <div className="col-12 col-sm-6">
                  <h3>Summer schools</h3>
                  <p>
                    Students spend two or more weeks in a traditional boarding
                    school setting, and experience exciting and innovative
                    academic courses which prepare students for the future
                  </p>
                </div>
              </div>
              <div className="row mb-0 mb-sm-5">
                <div className="col-12 col-sm-6">
                  <h3>International camps</h3>
                  <p>
                    Make friends with other students from over 150 countries,
                    and become part of a truly global community
                  </p>
                </div>
                <div className="col-12 col-sm-6">
                  <h3>Develop 21st century skills</h3>
                  <p>
                    Whether at pre-university camps or summer schools, you'll
                    develop independence, confidence and key skills like public
                    speaking and critical thinking
                  </p>
                </div>
              </div>
              <div className="row mb-0 mb-sm-5">
                <div className="col-12 col-sm-6">
                  <h3>Excursions</h3>
                  <p>
                    As part of your summer experience, you'll take part in
                    excursions to places like London, Oxford, and Cambridge,
                    visiting local attractions such as museums and galleries
                  </p>
                </div>
                <div className="col-12 col-sm-6">
                  <h3>Personalised experiences</h3>
                  <p>
                    One School will help you find the camp that is most suitable
                    for your needs and interests, ensuring that you have the
                    best summer experience possible!
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-4 ">
              <img src={sc2} className="img-fluid p-2 p-md-4" />
            </div>
            <div className="col-12 col-sm-4">
              <img src={sc3} className="img-fluid p-2 p-md-4" />
            </div>
            <div className="col-12 col-sm-4">
              <img src={sc4} className="img-fluid p-2 p-md-4" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExperienceSummer;
