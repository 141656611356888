export const ServiceCardBoarding = ({ title, description, icon }) => {
  return (
    <div className="service-card-boarding">
      <div className="service-card-align">
        <img src={icon} alt={title} className="service-icon-boarding" />
        <h3 className="service-title-boarding">{title}</h3>
      </div>
      <p className="service-description-boarding">{description}</p>
    </div>
  );
};
