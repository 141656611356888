import { Link } from "react-router-dom";
import "./LinkSectionStyles.css";
import linkIcon from "../assets/img/oneschool/Website/Images/linkIcon.png";

const links = [
  {
    id: 0,
    text: "See how we partner with families and students",
    link: "/for-students",
    icon: linkIcon,
  },
  {
    id: 1,
    text: "See how we partner with schools",
    link: "/for-schools",
    icon: linkIcon,
  },
  {
    id: 2,
    text: "Learn more about our Online School",
    link: "/online-school",
    icon: linkIcon,
  },
];

const LinksSection = () => {
  return (
    <div className="bg-blue-container">
      <div className="container py-5 ">
        <section className="link-section p-3 p-sm-5">
          {links.map((link) => {
            return (
              <Link
                key={link.id}
                className={`link-section-link d-flex text-white ${
                  link.id !== links.length - 1 ? "mb-5" : ""
                }`}
                to={link.link}
              >
                <h2 className="border-bottom d-flex align-items-start">
                  {link.text}
                  <img
                    src={link.icon}
                    className="img-fluid ms-2 object-fit-contain"
                  />
                </h2>
              </Link>
            );
          })}
        </section>
      </div>
    </div>
  );
};

export default LinksSection;
