import { Link } from "react-router-dom";

import HeroImage from "../assets/img/oneschool/Website/Images/HeroImage.png";
import HomePageBottomSlider from "./HomePageBottomSlider";
import finalist from "../assets/img/oneschool/Website/Images/finalist.png";

export const HeroSectionHome = () => {
  return (
    <div className="white-bg-container">
      <div className="container">
        <section className="hero-section">
          <div className="text-container">
            <h1>Your partner in education</h1>
            <p>
            We are an international education services provider that delivers British curriculum primary and secondary school education to students both directly, and through school partnerships around the world.
            </p>
            <img src={finalist} className="img-fluid hero-icon-mobile" />
            <Link to="/enquire" className="inquire-button">
              Learn more
            </Link>
            <img src={finalist} className="img-fluid mt-5 hero-icon-desktop" />
          </div>
          <div className="image-container-a">
            <img src={HeroImage} alt="People interacting" />
          </div>
        </section>
        <HomePageBottomSlider />
      </div>
    </div>
  );
};
