import { Link } from "react-router-dom";

export const HeroSectionAbout = () => {
  const oneSchoolMainPic = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="350.55"
      height="526.3"
      viewBox="0 0 369 554"
      fill="none"
    >
      <path
        d="M217.817 0.537109H151.183C120.138 0.537109 91.3642 10.0075 67.1338 25.3058C63.3477 27.4913 63.3477 32.5908 66.3765 35.5047L92.8786 61.0019C104.237 71.9293 120.138 75.5718 135.282 72.6578C140.582 71.9293 145.883 71.2008 151.183 71.2008H217.817C260.977 71.2008 295.051 104.711 295.051 145.507V407.764C295.051 449.288 260.22 482.07 217.817 482.07H151.183C108.023 482.07 73.9486 448.56 73.9486 407.764V145.507C73.9486 140.408 74.7058 135.308 75.463 130.209C78.4918 117.824 73.9486 104.711 64.1049 95.2411L35.3313 67.5584C32.3025 64.6444 27.0021 64.6444 23.9733 69.0153C9.58642 91.5986 0.5 117.824 0.5 146.235V408.493C0.5 488.627 67.8909 553.462 151.183 553.462H217.817C301.109 553.462 368.5 488.627 368.5 408.493V146.235C367.743 65.3729 300.352 0.537109 217.817 0.537109Z"
        fill="#0ABAB5"
      />
    </svg>
  );

  return (
    <div className="white-bg-container">
      <section className="hero-section-aboutus">
        <div className="text-container-aboutus">
          <h2>OUR VISION</h2>
          <h1>{window.screen.width > 425
            ? "Make high quality education accessible to all"
            : "Make excellent education accessible."}</h1>
          <p>
            Our mission is to ensure that all students around the world have
            access to a high-quality education, tailored to their specific
            needs. <br />
            <br /> We aim to instill in every student the belief that nothing is
            necessarily beyond their reach and that fear of failure should not
            prevent students from pursuing their passion. <br />
            <br /> To this end, we help our students develop the mindset and
            bravery to help grow the fundamental skills they need to tackle any
            problem
          </p>
          <Link to="/enquire" className="about-us-inquire-button">
            Enquire Now
          </Link>
        </div>
        <div className="aboutus-hero-image-container">
          <div className="aboutus-oneschoolpic">{oneSchoolMainPic}</div>
        </div>
      </section>
    </div>
  );
};
