import HeroComp from "./HeroComp";
import ImageLeftComp from "./ImageLeftComp";
import forStudentsHeroImage from "./assets/img/oneschool/Website/Images/forStudentsHero.jpg";
import forStudents2 from "./assets/img/oneschool/Website/Images/forStudents2.jpg";
import forStudents3 from "./assets/img/oneschool/Website/Images/forStudents3.jpg";
import forStudents4 from "./assets/img/oneschool/Website/Images/forStudents4.jpg";
import forStudents5 from "./assets/img/oneschool/Website/Images/forStudents5.jpg";
import forStudents6 from "./assets/img/oneschool/Website/Images/forStudents6.jpg";
import { ResultsSection } from "./components/ResultsSection";
import { Testimonials2 } from "./components/Testimonials2";
import HomePageBottomSlider2 from "./components/HomePageBottomSlider2";
import HomePageBottomSlider3 from "./components/HomePageBottomSlider3";
import { ContactSection } from "./components/ContactSection";

const content = [
  {
    id: 0,
    image: forStudents2,
    heading1: "Online School",
    heading2: "Welcome to our Online School",
    content:
      "As an online school accredited by Cambridge International Education, we give students access to full IGCSE and A-level curricula and exams. We provide both full online home-schooling and additional subjects to students based on their interests",
    buttons: [
      {
        type: "border",
        content: "Learn more",
        link: "/online-school",
      },
    ],
  },
  {
    id: 1,
    image: forStudents3,
    heading1: "School & University Admissions",
    heading2: "We are your educational mentors",
    content:
      "We work closely with students aiming to study in the UK or USA. We engage with you to understand your needs in depth, and create personalised educational plans to help you win entrance to your dream school or university, and help you thrive there",
    buttons: [],
  },
  {
    id: 2,
    image: forStudents4,
    heading1: "Subject Tutoring",
    heading2: "We offer bespoke subject support",
    content:
      "We offer personalised one-to-one and group classes to students delivered by highly-qualified and trained teachers. We teach students aged 8-18, across the entire UK national curriculum",
    buttons: [
      {
        type: "border",
        content: "Learn more",
        link: "/subject-tutoring",
      },
    ],
  },
  {
    id: 3,
    image: forStudents5,
    heading1: "Residential and day camps",
    heading2: "Discover life in the UK",
    content:
      "We work with partners who have designed immersive summer camps for students of all ages, hosted at prestigious locations such as Eton, Oxford, and Cambridge - contact us to find the camp most suitable for you!",
    buttons: [
      {
        type: "border",
        content: "Learn more",
        link: "/summer-camp",
      },
    ],
  },
  {
    id: 4,
    image: forStudents6,
    heading1: "English Language",
    heading2: "We offer IELTS and General English classes",
    content:
      "We run language classes for adult and child learners from beginner to advanced level. Our classes are conducted both online and in-person, and are tailored to our students' needs",
    buttons: [
      {
        type: "border",
        content: "Learn more",
        link: "/english-language",
      },
    ],
  },
];

const ForStudents = () => {
  return (
    <div>
      <HeroComp
        heading="For Students"
        para="Welcome to One School, your partner in a lifelong educational journey"
      />
      {content.map((c) => {
        return c.id % 2 ? (
          <ImageLeftComp isReverse={false} content={c} />
        ) : (
          <ImageLeftComp isReverse={true} content={c} />
        );
      })}
      <ResultsSection bg="white" />
      <Testimonials2 />
      <HomePageBottomSlider2 />
      <HomePageBottomSlider3 />
    </div>
  );
};

export default ForStudents;
