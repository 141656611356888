import "./ImageLeftCompStyles.css";

const ImageLeftCom2 = ({ isReverse, content }) => {
  return (
    <div
      className={
        !isReverse
          ? "blue-bg-container text-black"
          : "white-bg-container text-black"
      }
    >
      <div className="for-students-2 container py-5">
        <div className="row">
          {!isReverse ? (
            <>
              <div className="col-lg-6 col-sm-12 p-5 align-content-center">
                <h3 className="section-heading">{content.heading1}</h3>
                <p className="section-heading-content">{content.content}</p>
              </div>
              <div className="col-lg-6 col-sm-12 p-5 d-flex justify-content-end">
                <img src={content.image} className="img-fluid" />
              </div>
            </>
          ) : (
            <>
              <div className="col-lg-6 col-sm-12 p-5 d-flex ">
                <img src={content.image} className="img-fluid" />
              </div>
              <div className="col-lg-6 col-sm-12 p-5 align-content-center">
                <h3 className="section-heading">{content.heading1}</h3>
                <p className="section-heading-content">{content.content}</p>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ImageLeftCom2;
