import { useEffect, useRef, useState } from "react";
import VisibilitySensor from "react-visibility-sensor";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import useIntersectionObserver from "./components/scrollCheckCustomHook";

const stats = [
  {
    label: "See grade improvement within 3 months",
    value: "97%",
    color: "#09BAB5",
  },
  {
    label: "Admitted to top 2 choices of school",
    value: "100%",
    color: "#30ADE3",
  },
  {
    label: "Of our students are happy with the school we recommended",
    value: "100%",
    color: "#F17070",
  },
];

export const ResultsSection2 = ({bg}) => {
  const [elementRef, isVisible] = useIntersectionObserver({
    root: null, // Use the viewport as the root
    rootMargin: "0px",
    threshold: 0.1, // Trigger when 10% of the element is visible
  });

  return (
    // <div
    //   className={`blue-bg-container ${
    //     window.innerWidth <= 768 ? "no-animation" : ""
    //   }`}
    // >
    <div className={`${bg}-bg-container`}>
      {/* <VisibilitySensor onChange={onVisibilityChange}> */}
      <div className="results-section">
        <div className="results-content">
          <h2>RESULTS</h2>
          <h1>Outstanding results</h1>
          <p>
            Using our holistic education philosophy, we have consistently helped
            students achieve outstanding results and their desired goals
          </p>
        </div>
        <div ref={elementRef} className="results-stats">
          {stats.map((stat, index) => (
            <div className="result-item" key={index}>
              <div className="circle-container">
                <CircularProgressbar
                  strokeWidth={4}
                  value={isVisible ? 100 : 0}
                  text={`${isVisible ? stat.value : 0}`}
                  styles={buildStyles({
                    pathColor: stat.color,
                    textColor: stat.color,
                    trailColor: "#EEFBFA",
                    animationDuration: "5s",
                    textSize: "25px",
                  })}
                />
                <p className="stat-label" style={{ color: stat.color }}>
                  {stat.label}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* </VisibilitySensor> */}
    </div>
  );
};
