import React, { useState, useEffect } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "./AnimatedCicularProgressbarStyles.css";

const AnimatedCircularProgressbar = ({ stat, isVisible }) => {
  const [progress, setProgress] = useState(0);
  const [displayValue, setDisplayValue] = useState(0);

  useEffect(() => {
    if (isVisible) {
      let start = 0;
      const end = parseInt(stat.value);
      const duration = 2000; // Animation duration in ms
      const stepTime = Math.abs(Math.floor(duration / end));

      const timer = setInterval(() => {
        start += 1;
        setProgress((start / end) * 100);
        setDisplayValue(start);
        if (start === end) clearInterval(timer);
      }, stepTime);

      return () => clearInterval(timer);
    }
  }, [isVisible, stat.value]);

  return (
    <CircularProgressbar
      strokeWidth={4}
      value={progress}
      text={
        <tspan className="sliding-number">
          {displayValue}
          {typeof stat.value === "string" ? "%" : "+"}
        </tspan>
      }
      styles={buildStyles({
        pathColor: stat.color,
        textColor: stat.color,
        trailColor: "#EEFBFA",
        textSize: "25px",
      })}
    />
  );
};

export default AnimatedCircularProgressbar;
