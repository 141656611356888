import "./TeachersHighlyQualifiedStyles.css";
import image1 from "../assets/img/oneschool/Website/Images/qTeachers1.png";
import image2 from "../assets/img/oneschool/Website/Images/qTeachers2.png";
import image3 from "../assets/img/oneschool/Website/Images/qTeachers3.png";

const TeachersHighlyQualified2 = () => {
  return (
    <div className="blue-bg-container">
      <div className="container text-black text-center p-5 q-teachers">
        <h1 className="mb-3">Our professional teachers</h1>
        <p className="mb-5 w-md-50 w-lg-75 mx-auto">
          Our classes are led by fully qualified, professional teachers who are
          passionate about inspiring a love for learning, diverse thinking, and
          confidence in all their students. We provide them with all the tools,
          curricula, and content they need so they remain focused on guiding and
          mentoring each student.
        </p>
        <div className="row text-white">
          <div className="col mb-3">
            <div className="text-center card-green py-lg-4 px-lg-5 py-3 px-4 h-100 rounded-4 d-flex flex-column justify-content-start align-items-center">
              <img src={image1} className="img-fluid mb-3" />
              <p>
                OUR TEACHERS hold a teaching licence or certification from any
                of the following countries: UK, USA, Canada, Australia, New
                Zealand, or Ireland.
              </p>
            </div>
          </div>
          <div className="col mb-3">
            <div className="card-blue text-center py-lg-4 px-lg-5 py-3 px-4 h-100 rounded-4 d-flex flex-column justify-content-start align-items-center">
              <img src={image2} className="img-fluid mb-3" />
              <p>
                OUR TEACHERS are qualified to teach ESL, English Language and
                Literature, Maths, or Science to students aged between 7 and 16
              </p>
            </div>
          </div>
          <div className="col mb-3">
            <div className="text-center card-red py-lg-4 px-lg-5 py-3 px-4 h-100 rounded-4 d-flex flex-column justify-content-start align-items-center">
              <img src={image3} className="img-fluid mb-3" />
              <p>
                OUR TEACHERS have at least 3 years of classroom experience in
                primary and secondary schools.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeachersHighlyQualified2;
