import { Link } from "react-router-dom";
import el1 from "../assets/img/oneschool/Website/Images/el1.png";
import "./HeroSection2Styles.css";

const HeroSection3 = () => {
  return (
    <div>
      <div className="white-bg-container">
        <div className="container">
          <section className="hero-section">
            <div className="text-container d-flex flex-column justify-content-center align-items-start">
              <h1 className="heading lh-sm">English Language Training</h1>
              <p className="para mb-0">
                We recognise that education is a continuous journey that extends
                well beyond traditional schooling, embracing the rich tapestry
                of experiences that adult life brings. For us, excellence in
                adult education means providing a learning environment that
                respects and leverages the diverse backgrounds, skills, and
                goals of adult learners
              </p>
              <p className="para">
                Whether it's online or at our language centre in London, we
                support you. Everyone's learning needs and schedules are
                different - that's why we tailor classes to your pace and
                goals
              </p>
              <Link to="/enquire" className="inquire-button">
                Enroll Now
              </Link>
            </div>
            <div className="image-container-a">
              <img src={el1} alt="People interacting" />
            </div>
          </section>
          <div className="card-section container text-black text-center pb-5">
            <div className="row align-items-center justify-content-center">
              <div className="col-5 col-lg-3  ">
                <div className="p-3 p-lg-4 p-xl-5 m-2 m-lg-0 rounded-3">
                  <h4>Live classes</h4>
                  <p className="m-0">
                    Interactive and dynamic learning experience with expert
                    instructions
                  </p>
                </div>
              </div>
              <div className="col-5 col-lg-3  ">
                <div className="bg-blue p-3 p-lg-4 p-xl-5 m-2 m-lg-0 rounded-3">
                  <h4>Self-study</h4>
                  <p className="m-0">
                    Reinforce concepts and practice skills with materials and
                    homework
                  </p>
                </div>
              </div>
              <div className="col-5 col-lg-3  ">
                <div className="p-3 p-lg-4 p-xl-5 m-2 m-lg-0 rounded-3">
                  <h4>Feedback</h4>
                  <p className="m-0">
                    Make progress and refine understanding based on detailed
                    feedback
                  </p>
                </div>
              </div>
              <div className="col-5 col-lg-3  ">
                <div className="bg-blue p-3 p-lg-4 p-xl-5 m-2 m-lg-0 rounded-3">
                  <h4>Exam success</h4>
                  <p className="m-0">
                    Master exam techniques to excel and achieve your academic
                    goals
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection3;
