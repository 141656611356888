import ImageLeftCom4 from "./ImageLeftCom4";
import HeroSection6 from "./components/HeroSection6";
import ac1 from "./assets/img/oneschool/Website/Images/ac1.png";
import ac2 from "./assets/img/oneschool/Website/Images/ac2.png";
import ac3 from "./assets/img/oneschool/Website/Images/gscp.webp";
import ac4 from "./assets/img/oneschool/Website/Images/eng-lang.webp";

const content1 = {
  image: ac1,
  heading1: "Oxbridge Admissions",
  content:
    "Accelerator admissions programme for schools to prepare pupils for Oxford, Cambridge & other leading UK university admissions.",
};
const content2 = {
  image: ac3,
  heading1: "GCSE & A-level Courses",
  content:
    "In-curriculum Cambridge accredited GCSE & A-level courses in a variety of subjects, and academic enrichment classes.",
};
const content3 = {
  image: ac2,
  heading1: "Holiday Camps",
  content:
    "Academic & co-curricular camps in the UK for international students during the summer and winter holidays at leading schools & universities in the UK.",
};
const content4 = {
  image: ac4,
  heading1: "English Language Training",
  content:
    "Supporting international school pupils through academic English language preparation.",
};

const AcceleratorCourses = () => {
  return (
    <div>
      <HeroSection6 />
      <ImageLeftCom4 isReverse={true} content={content1} />
      <ImageLeftCom4 isReverse={false} content={content2} />
      <ImageLeftCom4 isReverse={true} content={content3} />
      <ImageLeftCom4 isReverse={false} content={content4} />
    </div>
  );
};

export default AcceleratorCourses;
