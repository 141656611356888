import { Table } from "react-bootstrap";
import tick from "./assets/img/oneschool/Website/Images/tick.svg";
import tickGrey from "./assets/img/oneschool/Website/Images/tick-grey.svg";
import "./TableCompOnlineSchoolStyles.css";
import { useState } from "react";

const TableCompOnlineSchool = () => {
  const [subjects, setSubjects] = useState([
    {
      id: 0,
      subject: {
        text: "Arabic",
        important: false,
      },
      ks2: "no-tick",
      ks3: "no-tick",
      igcse: "tick",
      aLevel: "tick",
      ib: "no-tick",
    },
    {
      id: 1,
      subject: {
        text: "Additional Mathematics",
        important: true,
      },
      ks2: "no-tick",
      ks3: "no-tick",
      igcse: "tick",
      aLevel: "tick",
      ib: "tick",
    },
    {
      id: 2,
      subject: {
        text: "Biology",
        important: true,
      },
      ks2: "no-tick",
      ks3: "tick",
      igcse: "tick",
      aLevel: "tick",
      ib: "tick",
    },
    {
      id: 3,
      subject: {
        text: "Business Studies",
        important: true,
      },
      ks2: "no-tick",
      ks3: "no-tick",
      igcse: "tick",
      aLevel: "tick",
      ib: "tick",
    },
    {
      id: 4,
      subject: {
        text: "Chemistry",
        important: true,
      },
      ks2: "no-tick",
      ks3: "tick",
      igcse: "tick",
      aLevel: "tick",
      ib: "tick",
    },
    {
      id: 5,
      subject: {
        text: "Classical Greek",
        important: false,
      },
      ks2: "no-tick",
      ks3: "no-tick",
      igcse: "tick",
      aLevel: "half-tick",
      ib: "no-tick",
    },
    {
      id: 6,
      subject: {
        text: "Classical Civilisation",
        important: false,
      },
      ks2: "no-tick",
      ks3: "no-tick",
      igcse: "no-tick",
      aLevel: "half-tick",
      ib: "no-tick",
    },
    {
      id: 7,
      subject: {
        text: "Computer Science",
        important: true,
      },
      ks2: "no-tick",
      ks3: "no-tick",
      igcse: "tick",
      aLevel: "tick",
      ib: "no-tick",
    },
    {
      id: 8,
      subject: {
        text: "Drama and Theatre Studies",
        important: false,
      },
      ks2: "no-tick",
      ks3: "no-tick",
      igcse: "no-tick",
      aLevel: "half-tick",
      ib: "no-tick",
    },
    {
      id: 9,
      subject: {
        text: "Design and Technology",
        important: false,
      },
      ks2: "no-tick",
      ks3: "no-tick",
      igcse: "no-tick",
      aLevel: "half-tick",
      ib: "no-tick",
    },
    {
      id: 10,
      subject: {
        text: "English Language",
        important: true,
      },
      ks2: "tick",
      ks3: "tick",
      igcse: "tick",
      aLevel: "tick",
      ib: "tick",
    },
    {
      id: 11,
      subject: {
        text: "English Literature",
        important: true,
      },
      ks2: "tick",
      ks3: "tick",
      igcse: "tick",
      aLevel: "tick",
      ib: "tick",
    },
    {
      id: 12,
      subject: {
        text: "Economics",
        important: true,
      },
      ks2: "no-tick",
      ks3: "no-tick",
      igcse: "tick",
      aLevel: "tick",
      ib: "tick",
    },
    {
      id: 13,
      subject: {
        text: "French",
        important: false,
      },
      ks2: "no-tick",
      ks3: "no-tick",
      igcse: "tick",
      aLevel: "half-tick",
      ib: "tick",
    },
    {
      id: 14,
      subject: {
        text: "Geography",
        important: false,
      },
      ks2: "no-tick",
      ks3: "no-tick",
      igcse: "tick",
      aLevel: "half-tick",
      ib: "tick",
    },
    {
      id: 15,
      subject: {
        text: "Government and Politics",
        important: false,
      },
      ks2: "no-tick",
      ks3: "no-tick",
      igcse: "no-tick",
      aLevel: "half-tick",
      ib: "no-tick",
    },
    {
      id: 16,
      subject: {
        text: "History",
        important: true,
      },
      ks2: "no-tick",
      ks3: "no-tick",
      igcse: "tick",
      aLevel: "tick",
      ib: "tick",
    },
    {
      id: 17,
      subject: {
        text: "History of Art",
        important: false,
      },
      ks2: "no-tick",
      ks3: "no-tick",
      igcse: "no-tick",
      aLevel: "half-tick",
      ib: "no-tick",
    },
    {
      id: 18,
      subject: {
        text: "Latin",
        important: false,
      },
      ks2: "no-tick",
      ks3: "no-tick",
      igcse: "tick",
      aLevel: "half-tick",
      ib: "no-tick",
    },
    {
      id: 19,
      subject: {
        text: "Mathematics",
        important: true,
      },
      ks2: "tick",
      ks3: "tick",
      igcse: "tick",
      aLevel: "tick",
      ib: "tick",
    },
    {
      id: 20,
      subject: {
        text: "Music",
        important: false,
      },
      ks2: "no-tick",
      ks3: "no-tick",
      igcse: "no-tick",
      aLevel: "half-tick",
      ib: "no-tick",
    },
    {
      id: 21,
      subject: {
        text: "Physics",
        important: true,
      },
      ks2: "no-tick",
      ks3: "tick",
      igcse: "tick",
      aLevel: "tick",
      ib: "tick",
    },
    {
      id: 22,
      subject: {
        text: "Physical Education",
        important: false,
      },
      ks2: "no-tick",
      ks3: "no-tick",
      igcse: "no-tick",
      aLevel: "half-tick",
      ib: "no-tick",
    },
    {
      id: 23,
      subject: {
        text: "Psychology",
        important: false,
      },
      ks2: "no-tick",
      ks3: "no-tick",
      igcse: "no-tick",
      aLevel: "half-tick",
      ib: "no-tick",
    },
    {
      id: 24,
      subject: {
        text: "Religious Studies",
        important: false,
      },
      ks2: "no-tick",
      ks3: "no-tick",
      igcse: "no-tick",
      aLevel: "half-tick",
      ib: "no-tick",
    },
    {
      id: 25,
      subject: {
        text: "Spanish",
        important: false,
      },
      ks2: "no-tick",
      ks3: "no-tick",
      igcse: "tick",
      aLevel: "half-tick",
      ib: "tick",
    },
  ]);

  return (
    <div className="white-bg-container text-black">
      <div className="container py-5">
        <Table bordered responsive>
          <thead>
            <tr>
              <th className="px-4 py-2 th col-4 text-nowrap">Subjects</th>
              <th className="px-4 py-2 th text-center col-1 text-nowrap">KS2</th>
              <th className="px-4 py-2 th text-center col-1 text-nowrap">KS3</th>
              <th className="px-4 py-2 th text-center col-1 text-nowrap">IGCSE</th>
              <th className="px-4 py-2 th text-center col-1 text-nowrap">A Level</th>
              <th className="px-4 py-2 th text-center col-1 text-nowrap">IB</th>
            </tr>
          </thead>
          <tbody>
            {subjects.map((subject) => {
              return (
                <tr key={subject.id}>
                  <td
                    className={`px-4 py-2 td ${
                      subject.subject.important && "fw-bold"
                    } ${subject.id % 2 !== 0 && "td-bg-colored"}`}
                  >
                    {subject.subject.text}
                    {subject.subject.important && " *"}
                  </td>
                  <td
                    className={`px-4 py-2 text-center ${
                      subject.id % 2 !== 0 && "td-bg-colored"
                    }`}
                  >
                    {subject.ks2 === "tick" && (
                      <img src={tick} className=" tick-img" />
                    )}
                    {subject.ks2 === "half-tick" && (
                      <img src={tickGrey} className=" tick-img" />
                    )}
                  </td>
                  <td
                    className={`px-4 py-2 text-center ${
                      subject.id % 2 !== 0 && "td-bg-colored"
                    }`}
                  >
                    {subject.ks3 === "tick" && (
                      <img src={tick} className=" tick-img" />
                    )}
                    {subject.ks3 === "half-tick" && (
                      <img src={tickGrey} className=" tick-img" />
                    )}
                  </td>
                  <td
                    className={`px-4 py-2 text-center ${
                      subject.id % 2 !== 0 && "td-bg-colored"
                    }`}
                  >
                    {subject.igcse === "tick" && (
                      <img src={tick} className=" tick-img" />
                    )}
                    {subject.igcse === "half-tick" && (
                      <img src={tickGrey} className=" tick-img" />
                    )}
                  </td>
                  <td
                    className={`px-4 py-2 text-center ${
                      subject.id % 2 !== 0 && "td-bg-colored"
                    }`}
                  >
                    {subject.aLevel === "tick" && (
                      <img src={tick} className=" tick-img" />
                    )}
                    {subject.aLevel === "half-tick" && (
                      <img src={tickGrey} className=" tick-img" />
                    )}
                  </td>
                  <td
                    className={`px-4 py-2 text-center ${
                      subject.id % 2 !== 0 && "td-bg-colored"
                    }`}
                  >
                    {subject.ib === "tick" && (
                      <img src={tick} className=" tick-img" />
                    )}
                    {subject.ib === "half-tick" && (
                      <img src={tickGrey} className=" tick-img" />
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <div className="table-note mt-4">
          <h3 className="mb-2">Note:</h3>
          <p>
            * Core subjects offered. Other subjects would require customisation
            of syllabus for specific needs, number of students, and start date.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TableCompOnlineSchool;
