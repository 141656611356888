export const TimelineCardUniversityUA = ({
  year,
  description,
  isSelected,
  onSelect,
}) => {
  return (
    <div
      className={`timeline-card ${isSelected ? "expanded" : ""}`}
      onClick={onSelect}
    >
      {year.label === "Oxbridge" && (
        <img
          src={year.upperIcon}
          alt={`${year.subtitle} icon`}
          className="card-icon"
        />
      )}
      {!isSelected && year.subtitle && (
        <div className="card-info">
          {year.icon && (
            <img
              src={year.icon}
              alt={`${year.subtitle} icon`}
              className="card-icon"
            />
          )}
          <p className="card-subtitle">{year.subtitle}</p>
        </div>
      )}
      <h3>{year.label}</h3>
      {isSelected && <p className="desc">{description}</p>}
      {isSelected && year.icons && (
        <div className="university-icon-container">
          {year.icons.map((icon, index) => (
            <img
              key={index}
              src={icon}
              alt={`Icon ${index + 1}`}
              className="university-icon"
            />
          ))}
        </div>
      )}
    </div>
  );
};
