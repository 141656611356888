import "./OurStoryAboutUsStyles.css";
import ourStoryImage1 from "../assets/img/oneschool/Website/Images/k.jpeg";
import ourStoryImage2 from "../assets/img/oneschool/Website/Images/aboutUsOurStory2.png";

const OurStoryAboutUs = () => {
  return (
    <div className="bg-white text-black">
      <div className=" container py-5">
        <div className="our-story-section">
          <div className="d-flex flex-column-reverse flex-lg-row">
            <div className="w-100 w-lg-50 mt-5 mt-lg-0">
              <h1>Our Story</h1>
              <p>
                Khurram and Shahryar’s journey to building One School started in
                1997, when they immigrated to the UK with their family. Like
                many others, initially they too struggled to adapt to their new
                environment. Determined to give their children the best start in
                life, their parents visited and met 27 schools and met each
                headmaster individually, before deciding on the right school for
                them. Within just a few years, Khurram won a place to start at
                Eton in 2003. Shahryar joined him in 2005, as a King’s Scholar.
                In 2011, and 2013, their youngest two brothers both also won
                King’s Scholarships to Eton. This marked the third time in
                Eton’s 600 year history where 3 brothers from the same family
                had won this award.
              </p>
              <p>
                Subsequently, Khurram won a John Jay Scholarship to Columbia
                University, and Shahryar a place at Oxford University. With
                their help, the youngest three brothers won places to King's
                College London and Oxford University, and pursued careers in
                Consulting and Investment Banking.
              </p>
              <p>
                Khurram and Shahryar founded One School to bring these same
                educational principles and values, unrelenting in their
                determination to make excellence in education a reality for
                every willing student.
              </p>
            </div>
            <div className="w-100 w-lg-50 mb-5 mb-lg-0 d-flex align-items-center justify-content-center px-5">
              <div className="rel">
                <img className="img1 img-fluid" src={ourStoryImage1} />
                <img className="img2 img-fluid" src={ourStoryImage2} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurStoryAboutUs;
