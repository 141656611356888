import ellipseGroupImage from "../assets/img/oneschool/Website/Images/ellipse_group.png";
import tutoringAcademy from "../assets/img/oneschool/Website/Images/tutoring_academy.jpg";
import tutoringAcademyGrouped from "../assets/img/oneschool/Website/Images/tutoring_academy_grouped.png";

export const OneSchoolAcademy = () => {
  return (
    <div className="white-bg-container">
      <section className="one-school-academy">
        <div className="ellipse-group-academy-top">
          <img
            src={ellipseGroupImage}
            alt="Decorative Ellipse"
            className="academyEllipse-top"
          />
        </div>
        <div className="ellipse-group-academy-bottom">
          <img
            src={ellipseGroupImage}
            alt="Decorative Ellipse"
            className="academyEllipse-bottom"
          />
        </div>
        <div className="academy-content">
          <div className="text-content-academy">
            <h2>One school academy</h2>
            <p>
              Our goal is to provide students with the highest quality education
              experience, and having an experienced and highly qualified team of
              teachers is key to achieving this goal. Our recruitment process
              ensures that we only attract the most suitable educators to meet
              the academic needs of our students.
            </p>
            <ul className="features-list-academy">
              <li>
                <span className="number">1</span> Multi-dimensional assessment
                <p className="subtext">
                  Our professional guidance counselors maintain close
                  communication with parents, sharing students' learning
                  progress to ensure that parents are aware of their children's
                  development.
                </p>
              </li>
              <li>
                <span className="number">2</span> Experienced British teachers
                <p className="subtext">
                  We recruit only outstanding teachers. This process includes
                  interviews, teaching demonstrations, and background checks.
                  Many of our teachers are curriculum designers and examiners
                  for IGCSEs / A-levels / IB.
                </p>
              </li>
              <li>
                <span className="number">3</span> UK qualified teachers
                <p className="subtext">
                  Our teachers are fully qualified from the UK, and have a
                  minimum of 5 years of classroom teaching experience in British
                  private schools. This ensures that they are familiar with the
                  educational environment and academic requirements of private
                  schools, as well as for both school and public exams.
                </p>
              </li>
              <li>
                <span className="number">4</span> Group classes
                <p className="subtext">
                  Group courses follow established curricula to assist students
                  in English reading and writing, exam preparation, revision,
                  academic reinforcement, and completing IGCSE/A-level/IB
                  subject course reviews in preparation for exams.
                </p>
              </li>
            </ul>
          </div>
          <div className="image-container-academy">
            <img
              src={tutoringAcademy}
              alt="Tutoring"
              className="tutoringAcademyPic"
            />
            <img
              src={tutoringAcademyGrouped}
              alt="Grouped images"
              className="tutoringAcademyGrouped"
            />
          </div>
        </div>
      </section>
    </div>
  );
};
