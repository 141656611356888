import { Link } from "react-router-dom";
import os1 from "../assets/img/oneschool/Website/Images/os1.png";
import "./HeroSection2Styles.css";

const HeroSection6 = () => {
  return (
    <div>
      <div className="white-bg-container">
        <div className="container">
          <section className="hero-section">
            <div className="text-container d-flex flex-column justify-content-center align-items-start">
              <h1 className="heading lh-sm">
                Your partner in improving pupil outcomes
              </h1>
              <p className="para">
                We work with schools around the world to enable them to offer
                high-quality in-curriculum education
              </p>
              <Link to="/enquire" className="inquire-button">
                Enroll Now
              </Link>
            </div>
            <div className="image-container-a">
              <img src={os1} alt="People interacting" />
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default HeroSection6;
