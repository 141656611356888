import ImageLeftCom3 from "./ImageLeftCom3";
import HeroSection3 from "./components/HeroSection3";
import el2 from "./assets/img/oneschool/Website/Images/el2.png";
import el3 from "./assets/img/oneschool/Website/Images/el3.png";
import { ContactSection } from "./components/ContactSection";

const content1 = {
  image: el2,
  heading1: "IELTS Prep",
  content:
    "Our IELTS prep course is designed to cover all aspects of the IELTS exam, providing students with strategies and advice to tackle each section effectively. This includes preparation for listening, reading, writing, and speaking parts, ensuring a well-rounded readiness for the exam.",
  accordian: [
    {
      id: 0,
      title: "Preparation",
      content:
        "Our IELTS course offers a holistic approach to exam readiness, covering all sections with strategic insights and targeted advice to help you achieve your desired scores",
    },
    {
      id: 1,
      title: "Feedback",
      content:
        "We provide you direct feedback to help you improve your scores",
    },
    {
      id: 2,
      title: "Skill enhancement",
      content:
        "Targeted support from your teacher will help you build your specific language skills",
    },
  ],
};

const content2 = {
  image: el3,
  heading1: "General English",
  content:
    "This course is designed for individuals keen on enhancing their linguistic proficiency for everyday communication, professional environments, and beyond. Our course is tailored to cater to a broad spectrum of needs, from daily interactions to the nuanced demands of the business world",
  accordian: [
    {
      id: 0,
      title: "Course philosophy",
      content:
        "We believe in the power of real-world application, interactive lessons, and cultural immersion to foster a deeper understanding and appreciation of the English language",
    },
    {
      id: 1,
      title: "Course structure",
      content:
        "Courses are 8 weeks long and classes each week will be 2 hours long. At the end of the course, you will receive a certificate",
    },
    {
      id: 2,
      title: "Progress and Feedback",
      content:
        "We help you develop and measure your progress through homework and mid-course tests, as well as personalised feedback from your teacher",
    },
  ],
};

const EnglishLang = () => {
  return (
    <div>
      <HeroSection3 />
      <ImageLeftCom3 isReverse={true} content={content1} />
      <ImageLeftCom3 isReverse={false} content={content2} />
    </div>
  );
};

export default EnglishLang;
