import comprehensivePlanning from "../assets/img/oneschool/Website/Icons/comprehensive_planning.svg";
import personalisedGuidance from "../assets/img/oneschool/Website/Icons/personalised_guidance.svg";
import applicationSupport from "../assets/img/oneschool/Website/Icons/application_support.svg";
import interviewExamPrep from "../assets/img/oneschool/Website/Icons/interview_exam_preparations.svg";
import visaAssistance from "../assets/img/oneschool/Website/Icons/visa_assistance.svg";
import { ServiceCardUniversityUA } from "./ServiceCardUniversityUA";

export const ServicesSectionUniversityUA = () => {
  const servicesData = [
    {
      title: "Comprehensive planning",
      description:
        "We plan the entire UK university application process, including organizing necessary extracurricular activities and providing the required academic support to ensure that students' applications are competitive.",
      icon: comprehensivePlanning,
    },
    {
      title: "Personalised guidance",
      description:
        "We provide personalised course and university selection guidance to each student, helping them make the most suitable choices based on their individual circumstances and goals.",
      icon: personalisedGuidance,
    },
    {
      title: "Application support",
      description:
        "We assist students with their UCAS applications, including drafting personal statements, resumes, and handling acceptance or rejection notifications, to ensure the quality and accuracy of their application materials.",
      icon: applicationSupport,
    },
    {
      title: "Interview and exam preparations",
      description:
        "We provide additional support, including helping students prepare for interviews at Oxford and Cambridge universities, as well as guidance for exam preparation.",
      icon: interviewExamPrep,
    },
    {
      title: "Visa assistance",
      description:
        "We assist students with visa matters, ensuring that they can legally enter the UK and commence their university life.",
      icon: visaAssistance,
    },
  ];

  return (
    <div className="blue-bg-container">
      <section className="services-section-university">
        <h2 className="services-title-university">Our services include</h2>
        <p className="services-subtitle-university">
          Our goal is to provide comprehensive university application support to
          students, ensuring that they achieve the best possible results during
          the application process and have the opportunity to successfully gain
          admission to top-tier universities in the UK. We focus on the
          individuality and needs of each student, providing them with tailored
          services to help them achieve their university dreams.
        </p>
        <div className="services-grid-university">
          {servicesData.map((service, index) => (
            <ServiceCardUniversityUA key={index} {...service} />
          ))}
        </div>
      </section>
    </div>
  );
};
