import React, { useRef, useState, useEffect } from 'react';
import './HomePage.css';
import './InquireNow.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from 'react-router-dom';
import oneSchoolIcon from '../assets/img/oneschool/Website/Icons/OneSchoolFullIcon.svg';
import locationIcon from '../assets/img/oneschool/Website/Images/locationIcon.svg';
import phoneIcon from '../assets/img/oneschool/Website/Images/phoneIcon.svg';
import envelopeIcon from '../assets/img/oneschool/Website/Images/envelopeIcon.svg';
import accreditationIcon from '../assets/img/oneschool/Website/Images/bsaCertification.png';
import facebookIcon from '../assets/img/oneschool/Website/Images/facebook-Icon.png';
import instagramIcon from '../assets/img/oneschool/Website/Images/instagram-Icon.png';
import linkedinIcon from '../assets/img/oneschool/Website/Images/linkedin-Icon.png';
import circleCheck from '../assets/img/oneschool/Website/Images/circle-check.svg';
import awardBadge from '../assets/img/oneschool/Website/Images/AwardBadge.jpg';
import 'react-circular-progressbar/dist/styles.css';
import { ContactSection } from './ContactSection';

const InquireNow = () => {
    return (
        <div>
            {/* <ContactSection /> */}
        </div>
    );
};

const Footer = () => {
    const [email, setEmail] = useState('');

    const handleEmailChange = (e) => setEmail(e.target.value);

    const handleSubmit = (e) => {
        e.preventDefault();
        // Add logic to handle email subscription
        alert(`Subscribed with: ${email}`);
        setEmail(''); // Reset email input after submit
    };

    return (
        <div className="white-bg-container">
            <div class="full-width-container">
                <footer className="footer">
                    <div className="footer-content">
                        <div className="footer-section contact-details">
                            <img src={oneSchoolIcon} alt="One School Logo" className="footer-logo" />
                            <div className="footer-contact-item">
                                <img src={locationIcon} alt="Location" className="footer-icon" />
                                <p>Abc Street postal code City Country</p>
                            </div>
                            <div className="footer-contact-item">
                                <img src={phoneIcon} alt="Phone" className="footer-icon" />
                                <p>020 8558 2634</p>
                            </div>
                            <div className="footer-contact-item">
                                <img src={envelopeIcon} alt="Email" className="footer-icon" />
                                <p><a href="mailto:oneschool@email.com">oneschool@email.com</a></p>
                            </div>
                        </div>
                        <div className="footer-section links">
                            <h4><Link to="/">Home</Link></h4>
                            <h4>Services</h4>
                            <div className="footer-links-postioning">
                                <p><Link to="/services-tutoring">Tutoring</Link></p>
                                <p><Link to="/services-school-admissions">School Admission</Link></p>
                                <p><Link to="/services-university-admissions">University Admission</Link></p>
                            </div>
                            <h4><Link to="/blog">Blog</Link></h4>
                            <h4><Link to="/about-us">About us</Link></h4>
                            <h4><Link to="/enquire-now">Contact us</Link></h4>
                        </div>
                        <div className="footer-section social">
                            <h4>Accreditations</h4>
                            <img src={accreditationIcon} alt="Accreditation" className="footer-accreditation-icon" />
                        </div>
                        <div className="footer-section newsletter">
                            <h4>Join our community</h4>
                            <p>Subscribe to get latest updates</p>
                            <form onSubmit={handleSubmit}>
                                <input
                                    type="email"
                                    placeholder="Write Your Email"
                                    value={email}
                                    onChange={handleEmailChange}
                                    required
                                />
                                <button type="submit">Subscribe</button>
                            </form>
                            <div className="footer-social-icons">
                                <a href="https://www.facebook.com/yourPage" target="_blank" rel="noopener noreferrer">
                                    <img src={facebookIcon} alt="Facebook" className="social-icon" />
                                </a>
                                <a href="https://www.instagram.com/yourPage" target="_blank" rel="noopener noreferrer">
                                    <img src={instagramIcon} alt="Instagram" className="social-icon" />
                                </a>
                                <a href="https://www.linkedin.com/in/yourPage" target="_blank" rel="noopener noreferrer">
                                    <img src={linkedinIcon} alt="LinkedIn" className="social-icon" />
                                </a>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
            <div className="footer-bottom">
                <p>© One School Private Limited</p>
            </div>
        </div>
    );
};

export default InquireNow;