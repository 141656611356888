export const ServiceCardUniversityUA = ({ title, description, icon }) => {
  return (
    <div className="service-card-university">
      <div className="service-card-align">
        <img src={icon} alt={title} className="service-icon-university" />
        <h3 className="service-title-university">{title}</h3>
      </div>
      <p className="service-description-university">{description}</p>
    </div>
  );
};
