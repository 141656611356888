import image1 from "../assets/img/oneschool/Website/Images/ourPhilAboutus1.png";
import image2 from "../assets/img/oneschool/Website/Images/ourPhilAboutus2.png";
import image3 from "../assets/img/oneschool/Website/Images/ourPhilAboutus3.png";
import image4 from "../assets/img/oneschool/Website/Images/ourPhilAboutus4.png";
import "./OurPhilosophyAboutUsStyles.css";

const iconBoxItems = [
  {
    id: 0,
    img: image1,
    title: "Critical Thinking",
    content:
      "Each student should develop the fundamental skills to think critically and analyse. Students are encouraged to ask questions and develop mental toolkits",
  },
  {
    id: 1,
    img: image2,
    title: "Holistic & Personalised approach",
    content:
      "Each student has their unique strengths – we take a personalised approach for each of our students tailored to their needs to help them unlock their potential",
  },
  {
    id: 2,
    img: image3,
    title: "Growth Mindset",
    content:
      "We want students to develop the mindset that most traits are not inherent talents but developed skills which they must work at consistently to improve. Using this approach will allow them to acquire new skills throughout their lives",
  },
  {
    id: 3,
    img: image4,
    title: "Bravery & Adaptability",
    content:
      "We want every student to develop the belief that they can ‘have a go’ at anything, without the fear of failure being a deterrent, and adapt to challenges which may arise",
  },
];

const OurPhilosophyAboutUs = () => {
  return (
    <div className="blue-bg-container text-black">
      <div className="container py-5">
        <div className="our-phil">
          <h1 className="mb-3">Our Philosophy</h1>
          <p>
            Our philosophy focuses on the core principles of education, where
            our pupils are inspired by the process of education itself,  where
            they learn to learn, adapt, and think.
          </p>
          <p>
            It is delivered through teaching excellence, comprehensive
            oversight, and partnership with families and schools to share
            responsibility for our pupils’ education.
          </p>
          <div className="row justify-content-between">
            {iconBoxItems.map((item) => {
              return (
                <div key={item.id} className="col-12 col-sm-6">
                  <div className="d-flex align-items-center mb-3">
                    <img
                      src={item.img}
                      className="img-fluid object-fit-contain me-3"
                    />
                    <h4>{item.title}</h4>
                  </div>
                  <p>{item.content}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurPhilosophyAboutUs;
